import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectPendingInvitesState = (state: RootState) => state.pendingInvites;

export const selectPendingTeamInvites = createSelector(
  selectPendingInvitesState,
  (state) => state.teamInvites
);

export const selectPendingCoachAdminInvites = createSelector(
  selectPendingInvitesState,
  (state) => state.coachAdminInvites
);

export const selectPendingInvitesPaginationData = createSelector(
  selectPendingInvitesState,
  (state) => state.paginationData
);

// This is used to manually update current team
// every time user sends invites is should also
// update license counts
export const selectUpdatedTeam = createSelector(
  selectPendingInvitesState,
  (state) => state.team
);
export const selectCustomErrors = createSelector(
  selectPendingInvitesState,
  (state) => state.customErrors
);

export const selectPendingInvitesInviteDetails = createSelector(
  selectPendingInvitesState,
  (state) => state.inviteDetails
);

export const selectPendingInvitesIsLoading = createSelector(
  selectPendingInvitesState,
  (state) => state.isLoading
);

export const selectPendingInvitesErrors = createSelector(
  selectPendingInvitesState,
  (state) => state.errors
);
