import { createSelector } from 'reselect';

import { RootState } from '../store';

const selectCurrentTeamState = (state: RootState) => state.currentTeam;

export const selectCurrentTeam = createSelector(
  selectCurrentTeamState,
  (state) => state.team
);

export const selectedSwimmer = createSelector(
  selectCurrentTeamState,
  (state) => state.selectedSwimmer
);

export const selectCurrentTeamLicenses = createSelector(
  selectCurrentTeamState,
  (state) => state.teamLicenses
);

export const selectCurrentTeamLocations = createSelector(
  selectCurrentTeamState,
  (state) => state.locations
);

export const selectCurrentTeamRole = createSelector(
  selectCurrentTeamState,
  (state) => state.teamRole
);

export const selectCurrentTeamGroups = createSelector(
  selectCurrentTeamState,
  (state) => [...state.groups]
);

export const selectCurrentTeamAdmins = createSelector(
  selectCurrentTeamState,
  (state) => state.admins
);

export const selectCurrentTeamCoaches = createSelector(
  selectCurrentTeamState,
  (state) => state.coaches
);

export const selectCurrentTeamSwimmers = createSelector(
  selectCurrentTeamState,
  (state) => state.swimmers
);

export const selectCurrentTeamSelectedGroup = createSelector(
  selectCurrentTeamState,
  (state) => state.group
);

export const selectCurrentTeamSelectedMember = createSelector(
  selectCurrentTeamState,
  (state) => state.selectedMember
);

export const selectDefaultGroup = createSelector(
  selectCurrentTeamState,
  (state) => state.defaultGroup
);

export const selectCurrentTeamErrors = createSelector(
  selectCurrentTeamState,
  (state) => state.errors
);

export const selectCurrentTeamIsLoading = createSelector(
  selectCurrentTeamState,
  (state) => state.isLoading
);
