import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { Group } from '../../types/group';
import { License } from '../../types/license';
import { Location } from '../../types/location';
import { ReduxState } from '../../types/redux-state';
import { Team, TeamMember } from '../../types/team';
import { Member } from '../../types/user';

import {
  fetchCurrentTeam,
  fetchCurrentTeamGroups,
  fetchCurrentTeamMembers,
  fetchCurrentTeamSelectedGroup,
  refetchCurrentTeam,
  refetchCurrentTeamGroups,
  resetCurrentTeamSelectedMember,
  updateTeam,
  uploadTeamLogo,
  resetCurrentTeamGroups,
  manualSetTeam,
  resetTeamRole,
  fetchCurrentTeamAllocatedLicense,
  fetchCurrentTeamLocations,
  saveCurrentTeamLocations,
  setSelectedSwimmer,
  fetchCurrentTeamCoaches,
} from './actions';
import {
  DUMMY_GROUP_DATA,
  DUMMY_MEMBERS_DATA,
} from '../../components/team-settings/groups/dummy';

interface CurrentTeamState extends ReduxState {
  team: Team;
  locations: Location[];
  teamLicenses: License[];
  group: Group;
  groups: Group[];
  teamRole: number;
  // Used so that when refetching team group we won't need to find the index again
  // but this might be obsolete already. To be tested soon
  defaultGroup: Group;
  groupFilter: {
    admin: number;
    coach: number;
    swimmer: number;
  };
  admins: Member[];
  coaches: Member[];
  swimmers: Member[];
  selectedMember: TeamMember | null;
  selectedSwimmer: Member | null;
}

const initialState: CurrentTeamState = {
  team: {
    teamId: '',
    photoURL: '',
    name: '',
    productId: '',
    licensesRemaining: 0,
    licenceAvailability: { totalLicence: 0, licenceInUse: 0, available: 0 },
  },
  locations: [],
  teamLicenses: [],
  teamRole: 0,
  group: {
    id: '',
    name: '',
    count: 0,
    members: [],
    status: 0,
  },
  groups: [],
  defaultGroup: null,
  groupFilter: {
    admin: 0,
    coach: 0,
    swimmer: 0,
  },
  admins: [],
  coaches: [],
  swimmers: [],
  selectedMember: null,
  selectedSwimmer: null,
  isLoading: false,
  errors: [],
};

export const currentTeamReducer = createReducer(initialState, (builder) => {
  // TODO: add refetch cases here
  // TODO: add pending lifecycle actions here to set the value being manipulated to its initialState
  builder
    .addCase(resetCurrentTeamGroups, (state) => {
      state.groups = initialState.groups;
      state.defaultGroup = initialState.defaultGroup;
      state.groupFilter = initialState.groupFilter;
    })
    .addCase(resetCurrentTeamSelectedMember, (state) => {
      state.selectedMember = initialState.selectedMember;
    })
    .addCase(resetTeamRole, (state) => {
      state.teamRole = 0;
    })
    .addCase(setSelectedSwimmer.fulfilled, (state, { payload }) => {
      state.selectedSwimmer = payload;
    })
    .addCase(fetchCurrentTeam.fulfilled, (state, { payload }) => {
      state.team = payload;
      // state.teamRole = payload.role;
    })
    .addCase(fetchCurrentTeamCoaches.fulfilled, (state, { payload }) => {
      state.coaches = payload;
      // state.teamRole = payload.role;
    })
    .addCase(fetchCurrentTeamGroups.fulfilled, (state, { payload }) => {
      state.groups = [...payload.groups];
      state.defaultGroup = { ...payload.defaultGroup };
      state.groupFilter = payload.groupFilter;
    })
    .addCase(fetchCurrentTeamSelectedGroup.fulfilled, (state, { payload }) => {
      state.group = { ...payload };
    })
    .addCase(fetchCurrentTeamMembers.fulfilled, (state, { payload }) => {
      // state.admins = [...payload.admins];
      // state.coaches = [...payload.coaches];
      state.swimmers = [...payload.swimmers];
    })
    // .addCase(fetchCurrentTeamSelectedMember.fulfilled, (state, { payload }) => {
    //   state.selectedMember = payload;
    // })
    .addCase(
      fetchCurrentTeamAllocatedLicense.fulfilled,
      (state, { payload }) => {
        state.teamLicenses = [...payload];
      }
    )
    // .addCase(uploadTeamLogo.fulfilled, (state, { payload }) => {
    //   state.team.photoURL = payload.photoURL;
    // })
    .addCase(updateTeam.fulfilled, (state, { payload }) => {
      state.team = payload;
    })
    .addCase(fetchCurrentTeamLocations.fulfilled, (state, { payload }) => {
      state.locations = payload;
    })
    .addCase(manualSetTeam, (state, team: any) => {
      state.team = team.payload as Team;
    })
    .addCase(refetchCurrentTeam.fulfilled, (state, { payload }) => {
      state.team = payload;
    })
    .addMatcher(
      isAnyOf(
        fetchCurrentTeam.pending,
        fetchCurrentTeamCoaches.pending,
        fetchCurrentTeamGroups.pending,
        fetchCurrentTeamMembers.pending,
        fetchCurrentTeamSelectedGroup.pending,
        refetchCurrentTeam.pending,
        refetchCurrentTeamGroups.pending,
        updateTeam.pending,
        uploadTeamLogo.pending,
        saveCurrentTeamLocations.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        fetchCurrentTeam.fulfilled,
        fetchCurrentTeamCoaches.fulfilled,
        fetchCurrentTeamGroups.fulfilled,
        fetchCurrentTeamMembers.fulfilled,
        fetchCurrentTeamSelectedGroup.fulfilled,
        refetchCurrentTeam.fulfilled,
        refetchCurrentTeamGroups.fulfilled,
        updateTeam.fulfilled,
        uploadTeamLogo.fulfilled,
        saveCurrentTeamLocations.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchCurrentTeam.rejected,
        fetchCurrentTeamCoaches.rejected,
        fetchCurrentTeamGroups.rejected,
        fetchCurrentTeamMembers.rejected,
        fetchCurrentTeamSelectedGroup.rejected,
        refetchCurrentTeam.rejected,
        refetchCurrentTeamGroups.rejected,
        updateTeam.rejected,
        uploadTeamLogo.rejected,
        saveCurrentTeamLocations.rejected
      ),
      (state, { payload }) => {
        state.errors = payload as string[];
        state.isLoading = false;
      }
    );
});
