import { createAction } from '@reduxjs/toolkit';

import { SelectedStroke, Stroke } from '../../types/chart';

const CHART_TYPE = 'chart';

export const updateLapCount = createAction<number>(
  `${CHART_TYPE}/updateLapCount`
);

export const updateLeftStrokeCount = createAction<number>(
  `${CHART_TYPE}/updateLeftStrokeCount`
);

export const updateRightStrokeCount = createAction<number>(
  `${CHART_TYPE}/updateRightStrokeCount`
);

export const updateCurrentStroke = createAction<Stroke>(
  `${CHART_TYPE}/updateCurrentStroke`
);

export const updateFilter = createAction<Stroke>(`${CHART_TYPE}/updateFilter`);

export const updateSelectedStrokes = createAction<SelectedStroke[]>(
  `${CHART_TYPE}/updateSelectedStrokes`
);

export const updateTimeValue = createAction<number>(
  `${CHART_TYPE}/updateTimeValue`
);

export const setChartTab = createAction<string>(`${CHART_TYPE}/setChartTab`);

export const setChartIsLoading = createAction<boolean>(
  `${CHART_TYPE}/setChartIsLoading`
);

export const toggleVideoSync = createAction(`${CHART_TYPE}/toggleVideoSync`);
