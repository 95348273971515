import { createReducer } from '@reduxjs/toolkit';

import { setIsLoading } from './actions';

type LoadingState = {
  isLoading: boolean;
};

const initialState: LoadingState = {
  isLoading: false,
};

export const loadingReducer = createReducer(initialState, (builder) => {
  builder.addCase(setIsLoading, (state, { payload }) => {
    state.isLoading = payload;
  });
});
