import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import apiDelete from '../../utils/api-delete';
import apiGet from '../../utils/api-get';
import apiPost from '../../utils/api-post';
import apiPatch from '../../utils/api-patch';

import { SWIMBETTER_ENDPOINT } from '../../constants/api-endpoints';

import { addNotification, catchErrors } from '../notifications';

import { TeamDetails } from '../../types/team';
import { License } from '../../types/license';

import { computeRemainingValidity } from '../../utils/license';

type DeleteTeamMemberData = {
  data: {
    teamId: string;
    userId: string;
    memberType: number;
  };
  successMessage: string;
};

type CreateTeamData = {
  data: {
    teamName: string;
  };
  successMessage: string;
};

type AllocateLicenseData = {
  data: {
    teamId: string;
    quantity: number;
  };
  successMessage: string;
};

const TEAM_ENDPOINT = `${SWIMBETTER_ENDPOINT}/team`;
const TEAM_LICENSES_ENDPOINT = `${SWIMBETTER_ENDPOINT}/license/team`;

export const fetchTeam = createAsyncThunk(
  'teams/created-user',
  async (payload: { userId: string }, { dispatch, rejectWithValue }) => {
    try {
      const result = await apiGet(
        `${TEAM_ENDPOINT}/created-user?userId=${payload.userId}`
      );

      return result.reverse();
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const resetMemberDeletedStatus = createAction(
  'teams/resetMemberDeletedStatus'
);

export const deleteTeamMember = createAsyncThunk(
  'teams/deleteTeamMember',
  async (payload: DeleteTeamMemberData, { dispatch, rejectWithValue }) => {
    try {
      // note: I already inquired to the api about why not use body instead of doing this param query,
      // will have to discuss more
      // but for now, this is the working api call
      const response = await apiDelete(
        `${TEAM_ENDPOINT}/remove-member/?teamId=${payload.data.teamId}&userId=${payload.data.userId}&memberType=${payload.data.memberType}`
      );
      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const createTeam = createAsyncThunk(
  'team/create-by-name',
  async (payload: CreateTeamData, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${TEAM_ENDPOINT}/create-by-name?name=${payload.data.teamName}`
      );

      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchTeamLicenses = createAsyncThunk(
  'teams/license/created-user',
  async (payload: { userId: string }, { dispatch, rejectWithValue }) => {
    try {
      const licenses = await apiGet(
        `${TEAM_LICENSES_ENDPOINT}/created-user?userId=${payload.userId}`,
        payload
      );

      const currentDate = new Date();
      // Setting the hours of the day to 0,0,0,0 which is 12 midnight
      // To compute the remaining days by days alone excluding time
      currentDate.setHours(0, 0, 0, 0);

      return licenses.map((license: License) =>
        computeRemainingValidity(license, currentDate)
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const teamSelect = createAsyncThunk(
  'teams/selectedTeam',
  async (payload: TeamDetails, { dispatch, rejectWithValue }) => {
    try {
      return payload;
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const allocateTeamLicense = createAsyncThunk(
  'teams/license/team/assign-quantity',
  async (payload: AllocateLicenseData, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPatch(
        `${TEAM_LICENSES_ENDPOINT}/assign-quantity`,
        payload.data
      );

      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const fetchTeamAllocatedLicense = createAsyncThunk(
  'teams/license/team',
  async (payload: { teamId: string }, { dispatch, rejectWithValue }) => {
    try {
      const licenses = await apiGet(
        `${TEAM_LICENSES_ENDPOINT}?teamId=${payload.teamId}`,
        payload
      );
      const currentDate = new Date();
      // Setting the hours of the day to 0,0,0,0 which is 12 midnight
      // To compute the remaining days by days alone excluding time
      currentDate.setHours(0, 0, 0, 0);

      return licenses.map((license: License) =>
        computeRemainingValidity(license, currentDate)
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const updateAvailableLicense = createAction<number>(
  `team/license/available`
);

export const resetTeamState = createAction(`team/reset`);

export const unsetTeamAllocatedLicense = createAction(
  `team/allocated/license/unset`
);
