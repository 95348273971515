import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { SWIMBETTER_ENDPOINT } from '../../constants/api-endpoints';
import { USER_TYPE } from '../../constants/user';

import {
  ConfirmAccountCredentials,
  EmailCredentials,
  GoogleCredentials,
  ThirdPartyCredentials,
} from '../../types/auth';
import { User } from '../../types/user';

import apiGet from '../../utils/api-get';
import apiPatch from '../../utils/api-patch';
import apiPost from '../../utils/api-post';

import { addNotification, catchErrors } from '../notifications';
import { RootState } from '../store';

const SIGN_IN_ENDPOINT = 'signin';
const SIGN_UP_ENDPOINT = 'signup';
const USER_ENDPOINT = `${SWIMBETTER_ENDPOINT}/user`;
const CONFIRM_CODE_ENDPOINT = 'code';

export const signInUser = createAction<User>('user/signInUser');
export const signOutUser = createAction('user/signOutUser');
export const resetSignUpCredentials = createAction(
  'user/resetSignUpCredentials'
);
export const resetUserHasSignOut = createAction('user/resetUserHasSignOut');
export const resetErrors = createAction('currentTeam/resetErrors');

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (payload: Partial<User>, { dispatch, rejectWithValue }) => {
    try {
      await apiPatch(USER_ENDPOINT, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const fetchUserSubscriptions = createAsyncThunk(
  'user/fetchUserSubscriptions',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await apiGet(`${USER_ENDPOINT}/subscription`);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const signInEmail = createAsyncThunk(
  'user/signInEmail',
  async (payload: EmailCredentials, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(`${SIGN_IN_ENDPOINT}/email`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const signInGoogle = createAsyncThunk(
  'user/signUpGoogle',
  async (payload: GoogleCredentials, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(`${SIGN_IN_ENDPOINT}/google`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const signInApple = createAsyncThunk(
  'user/signUpApple',
  async (payload: ThirdPartyCredentials, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(`${SIGN_IN_ENDPOINT}/apple`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const signUpEmail = createAsyncThunk(
  'user/signUpEmail',
  async (payload: EmailCredentials, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(`${SIGN_UP_ENDPOINT}/email`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const signUpGoogle = createAsyncThunk(
  'user/signUpGoogle',
  async (payload: GoogleCredentials, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(`${SIGN_UP_ENDPOINT}/google`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const signUpApple = createAsyncThunk(
  'user/signUpApple',
  async (payload: ThirdPartyCredentials, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(`${SIGN_UP_ENDPOINT}/apple`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const confirmUser = createAsyncThunk(
  'user/confirmUser',
  async (payload: ConfirmAccountCredentials, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(`${CONFIRM_CODE_ENDPOINT}/confirm`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const resendConfirmationCode = createAsyncThunk(
  'user/resendConfirmationCode',
  async (payload: { key: string }, { dispatch, rejectWithValue }) => {
    try {
      await apiPost(`${CONFIRM_CODE_ENDPOINT}/resend`, payload);

      dispatch(
        addNotification({
          message:
            'Confirmation code was sent successfully. Please check your email.',
          type: 'success',
        })
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const refetchUser = createAsyncThunk<User, void, { state: RootState }>(
  'user/refetchUser',
  async (_, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const { user } = thunkAPI.getState();

      const fetchedUser = await apiGet(
        `${USER_ENDPOINT}?userId=${user?.user?.id}`
      );

      return { ...user.user, ...fetchedUser };
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchUserTeams = createAsyncThunk(
  'user/fetchUserTeams',
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      const result = await apiGet(`${USER_ENDPOINT}/team?userId=${userId}`);

      return result.reverse();
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

// TODO: Verify if this is working as intended
// export const fetchUserTeams = createAsyncThunk<any, void, { state: RootState }>(
//   'user/fetchUserTeams',
//   async (_, thunkAPI) => {
//     return [
//       {
//         teamId: '',
//         name: 'Personal (Individual Subscription)',
//         role: USER_TYPE.SWIMMER,
//       },
//     ];

// ! This is temporarily as the new workflow is still being developed
//
// const { dispatch, rejectWithValue } = thunkAPI;
// const { user } = thunkAPI.getState();
// const individualSubscription = user.userSubscriptions.find(
//   (subscription: UserSubscription) =>
//     LICENSE_TYPES.INDIVIDUAL === subscription.type && !subscription.teamId
// );

// // type in Member is role in UserTeam
// const uniqueTeams = user.user.member.reduce((teams, { teamId, type }) => {
//   // Get the team from the list of unique teams to check
//   // if its role is already the highest one that the user is given
//   const teamIndex = teams.findIndex((team) => team.teamId === teamId);
//   if (teamIndex >= 0) {
//     const team = teams[teamIndex];

//     if (team.role > type) {
//       teams[teamIndex].role = type;
//     }

//     return teams;
//   } else {
//     return [...teams, { teamId, type: LICENSE_TYPES.TEAM, role: type }];
//   }
// }, []);

// try {
//   const userTeams = await Promise.all(
//     // I only return the team name and id as in this point it is the only relevant details
//     uniqueTeams.map(async (uniqueTeam) => {
//       const { teamId } = uniqueTeam;

//       const team = await apiGet(`${SWIMBETTER_ENDPOINT}/team`, { teamId });
//       const name = team.teamId ? team.name : '(Unnamed team)';
//       return { ...uniqueTeam, name };
//     })
//   );

//   // * If user has an individual subscription add it in userTeams as it is used for the dropdown
//   return individualSubscription
//     ? [
//         {
//           teamId: '',
//           name: 'Personal (Individual Subscription)',
//           type: LICENSE_TYPES.INDIVIDUAL,
//           role: USER_TYPE.SWIMMER,
//         },
//         ...userTeams,
//       ]
//     : userTeams;
// } catch (e: any) {
//   dispatch(catchErrors(e));
//   throw rejectWithValue(e);
// }
//   }
// );
