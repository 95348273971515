import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { Profile } from '../../types/profile';
import { ReduxState } from '../../types/redux-state';

import { fetchProfile, updateProfile, createProfile } from './actions';

interface ProfileState extends ReduxState {
  profile: Profile;
}

const initialState: ProfileState = {
  profile: {
    userId: '',
  },
  isLoading: false,
  errors: [],
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProfile.fulfilled, (state, { payload }) => {
      state.profile = { ...payload };
    })
    .addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.profile = { ...payload };
    })
    .addCase(createProfile.fulfilled, (state, { payload }) => {
      state.profile = { ...payload };
    })
    .addMatcher(
      isAnyOf(
        fetchProfile.pending,
        updateProfile.pending,
        createProfile.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        fetchProfile.fulfilled,
        fetchProfile.rejected,
        updateProfile.fulfilled,
        updateProfile.rejected,
        createProfile.fulfilled,
        createProfile.rejected
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchProfile.rejected,
        updateProfile.rejected,
        createProfile.rejected
      ),
      (state, { payload }) => {
        state.errors = payload as string[];
      }
    );
});
