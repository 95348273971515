import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { resetSuccessStatus } from '.';

import { PaginationData } from '../../types/pagination';
import { ReduxState } from '../../types/redux-state';

import {
  acceptJoinRequest,
  declineIndividualRequest,
  declineJoinRequest,
  fetchJoinRequestsList,
  sendJoinRequest,
} from './actions';

interface JoinRequestsState extends ReduxState {
  paginationData: PaginationData;
  joinRequest: [];
  isSuccess: boolean;
}

const initialState: JoinRequestsState = {
  paginationData: {
    data: [],
    pageNumber: 1,
    pageSize: 25,
    totalPages: 1,
    totalRecords: 0,
  },
  joinRequest: [],
  isSuccess: false,
  isLoading: false,
  errors: [],
};

export const joinRequestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetSuccessStatus, (state) => {
      state.isSuccess = false;
    })
    .addCase(acceptJoinRequest.fulfilled, (state, { payload }) => {
      state.isSuccess = payload;
    })
    .addCase(declineJoinRequest.fulfilled, (state, { payload }) => {
      state.isSuccess = payload;
    })
    .addCase(fetchJoinRequestsList.fulfilled, (state, { payload }) => {
      state.joinRequest = payload;
    })
    .addCase(fetchJoinRequestsList.pending, (state) => {
      state.paginationData = initialState.paginationData;
    })
    .addMatcher(
      isAnyOf(
        acceptJoinRequest.pending,
        declineJoinRequest.pending,
        // fetchJoinRequestsList.pending,
        sendJoinRequest.pending,
        declineIndividualRequest.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        acceptJoinRequest.fulfilled,
        declineJoinRequest.fulfilled,
        fetchJoinRequestsList.fulfilled,
        sendJoinRequest.fulfilled,
        declineIndividualRequest.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        acceptJoinRequest.rejected,
        declineJoinRequest.rejected,
        fetchJoinRequestsList.rejected,
        sendJoinRequest.rejected,
        declineIndividualRequest.rejected
      ),
      (state, { payload }) => {
        state.errors = payload as string[];
        state.isLoading = false;
      }
    );
});
