const SWIMBETTER_ENDPOINT = 'swim';
const DASHBOARD_ENDPOINT = 'dashboard';
const INVITE_ENDPOINT = `${SWIMBETTER_ENDPOINT}/invite`;
const LICENSES_ENDPOINT = `${SWIMBETTER_ENDPOINT}/license`;
const USER_ENDPOINT = `${SWIMBETTER_ENDPOINT}/user`;


// Video-Chart Functionality Related API EndPoints;
const CLIENT_ENDPOINT = process.env.API_ENDPOINT_VIDEOCHART;
// const UPLOAD_VIDEO_ENDPOINT = "https://dev-eolab-swim-video-upload.s3.ap-southeast-2.amazonaws.com/";
const GET_VIDEO_ENDPOINT = "swim/video/";
const PRESIGNED_APIURL_ENDPOINT = "swim/video/video_url/";
const UPDATE_METADATA_ENDPOINT = "swim/video/metadata/";
const DELETE_VIDEO_ENDPOINT = "swim/video/";
const CHART_DATA_ENDPOINT = `${SWIMBETTER_ENDPOINT}/chart/lapforcetime-swim`
// test aaron pipeline
export {
  SWIMBETTER_ENDPOINT,
  INVITE_ENDPOINT,
  LICENSES_ENDPOINT,
  USER_ENDPOINT,
  DASHBOARD_ENDPOINT,
  GET_VIDEO_ENDPOINT,
  PRESIGNED_APIURL_ENDPOINT,
  //UPLOAD_VIDEO_ENDPOINT,
  UPDATE_METADATA_ENDPOINT,
  DELETE_VIDEO_ENDPOINT,
  CHART_DATA_ENDPOINT,
  CLIENT_ENDPOINT,
};
