import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  fetchProductsList,
  resetProducts,
  subscribeToProduct,
} from './actions';
import { ProductsState } from '../../types/product';

const initialState: ProductsState = {
  productsList: [],
  isLoading: false,
  errors: [],
};

export const productsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetProducts, (state) => {
      state = initialState;
    })
    .addCase(fetchProductsList.fulfilled, (state, { payload }) => {
      state.productsList = payload;
    })
    .addMatcher(
      isAnyOf(fetchProductsList.pending, subscribeToProduct.pending),
      (state) => {
        state.isLoading = true;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchProductsList.fulfilled,
        fetchProductsList.rejected,
        subscribeToProduct.fulfilled,
        subscribeToProduct.rejected
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(fetchProductsList.rejected, subscribeToProduct.rejected),
      (state, { payload }) => {
        state.errors = payload as string[];
      }
    );
});
