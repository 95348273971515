import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SwimData } from '../../types/swim-data';
import { RootState } from '../store';
import apiGet from '../../utils/api-get';
import {
  DASHBOARD_ENDPOINT,
  SWIMBETTER_ENDPOINT,
} from '../../constants/api-endpoints';
import { addNotification, catchErrors } from '../notifications';
import {
  DashboardPreference,
  DefaultChartItem,
  ProgressionData,
  SwimListTable,
} from '../../types/dashboard';
import apiPost from '../../utils/api-post';
import apiPatch from '../../utils/api-patch';
import apiDelete from '../../utils/api-delete';

interface DefaultDashboard {
  userId: string;
  stroke: string;
  poolDistance: string;
  distance: number;
  chartOptions: DefaultChartItem[];
}

export interface PostDashboardListData {
  userId: string;
  dashboardId: string;
  isVideoOn?: boolean;
  dateTimeFilter: DateTimeFilter;
  pageNo: number;
}

export interface PostProgressChart {
  userId: string;
  dashboardId: string;
  dateTimeFilter: DateTimeFilter;
  isVideoOn: boolean;
}

export enum TimeFilter {
  TODAY = 0,
  SEVEN_DAYS = 1,
  THIRTY_DAYS = 2,
  NINETY_DAYS = 3,
  CUSTOM = 4,
  ALL_TIME = 5,
}

export interface PostDashboardIsFavorite {
  userId: string;
  dashboardId: string;
  dateTimeFilter: DateTimeFilter;
  isFavorite: boolean;
}

export interface DateTimeFilter {
  time: TimeFilter;
  customStartDate: string;
  customEndDate: string;
  timezone?: string;
}

export interface DashboardDateRange {
  time: TimeFilter;
  customStartDate: string;
  customEndDate: string;
  customString: string;
}

const DATA_DASHBOARD_ENDPOINT = `${SWIMBETTER_ENDPOINT}/data/dashboard`;

const PROGRESSION = `${SWIMBETTER_ENDPOINT}/chart/progression-charts`;

export const toggleShowPreferenceModal = createAction<string>(
  'dashboard/toggleShowPreferenceModal'
);

export const updateSummaryStroke = createAction<string>(
  'dashboard/updateSummaryStroke'
);

export const updateSummaryPoolType = createAction<string>(
  'dashboard/updateSummaryPoolType'
);

export const updateSummaryDistance = createAction<string>(
  'dashboard/updateSummaryDistance'
);

export const updateDefaultChartList = createAction<DefaultChartItem[]>(
  'dashboard/updateDefaultChartList'
);
export const setSelectedChart = createAction<DefaultChartItem>(
  'dashboard/setSelectedChart'
);

export const toggleSideNav = createAction('dashboard/toggleSideNav');

export const setDefaultChartList = createAction<DefaultChartItem[]>(
  'dashboard/setDefaultChartList'
);
export const setDashboardSummaryData = createAsyncThunk(
  'dashboard/setDashboardSummaryData',
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      return data;
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const fetchSwimDashboardData = createAsyncThunk<
  SwimData,
  string,
  { state: RootState }
>('dashboard/fetchSwimDashboardData', async (id: string, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  const state = getState();
  try {
    const { selectedSummary, defaultSummary } = thunkAPI.getState().dashboard;
    const response = await apiGet(
      `${DATA_DASHBOARD_ENDPOINT}?userId=${state.swimData.currentSwimUser}`
    );

    if (
      selectedSummary.stroke === '' &&
      selectedSummary.poolType === '' &&
      selectedSummary.distance === ''
    ) {
      const stroke = Object.keys(response)[0];
      const poolType = Object.keys(response[`${stroke}`])[0];
      const distance = Object.keys(response[`${stroke}`][`${poolType}`])[0];

      if (defaultSummary === null) {
        dispatch(updateSummaryStroke(stroke));
        dispatch(updateSummaryPoolType(poolType));
        dispatch(updateSummaryDistance(distance));
      }
    }
    return response;
  } catch (e: any) {
    dispatch(catchErrors(e));
    throw rejectWithValue(e);
  }
});

export const fetchDefaultDashboard = createAsyncThunk(
  'currentTeam/fetchDefaultDashboard',
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet(`${DASHBOARD_ENDPOINT}?userId=${userId}`);

      const newStr = response.PoolDistance.replace(/[^\d.-]/g, '');
      const poolType = response.PoolDistance.includes('meters')
        ? `${newStr}m`
        : `${newStr}y`;

      if (response?.Stroke !== '0') {
        dispatch(updateSummaryStroke(response?.Stroke.toLowerCase()));
        dispatch(updateSummaryPoolType(poolType));
        dispatch(updateSummaryDistance(`${response.distance}`));
      }

      return response;
    } catch (e: any) {
      // dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const saveDefaultDashboard = createAsyncThunk(
  'currentTeam/updateTeam',
  async (payload: DefaultDashboard, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(DASHBOARD_ENDPOINT, payload);

      dispatch(
        addNotification({
          message: 'Save default preferences',
          type: 'success',
        })
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const patchDefaultDashboard = createAsyncThunk(
  'currentTeam/updateTeam',
  async (payload: DefaultDashboard, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPatch(DASHBOARD_ENDPOINT, payload);

      dispatch(
        addNotification({
          message: 'Save default preferences',
          type: 'success',
        })
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchProgressionCharts = createAsyncThunk(
  'currentTeam/fetchProgressionCharts',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(PROGRESSION, payload);

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchChartNameList = createAsyncThunk(
  'currentTeam/fetchChartNameList',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet(`${DASHBOARD_ENDPOINT}/chart/name/list`);
      const newChartData = response.map((item: any, index: number) => {
        let chartItem = { ...item };
        chartItem.sequence = index + 1;
        chartItem.featured = true;

        return chartItem;
      });

      return newChartData;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

/************** V3 ********* */

export const selectDashboardPreference = createAction<DashboardPreference>(
  'dashboard/selectDashboardPreference'
);

export const setPreferenceModalAction = createAction<'add' | 'edit' | null>(
  'dashboard/setPreferenceModalAction'
);

export const fetchDashboardPreference = createAsyncThunk(
  'currentTeam/fetchDashboardPreference',
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet(
        `${DASHBOARD_ENDPOINT}/list/?userId=${userId}`
      );
      const initializeAllSwim = [
        ...response,
        {
          dashboardId: 'default',
          dashboardName: 'All Swims',
          userId: userId,
          stroke: '',
          poolDistance: '',
          distance: 0,
          chartOptions: [],
        },
      ];
      return initializeAllSwim;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const patchUserDefinedDashboardPreference = createAsyncThunk(
  'currentTeam/patchUserDefinedDashboardPreference',
  async (
    payload: {
      dashboardId: string;
      data: DashboardPreference;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await apiPatch(
        `${DASHBOARD_ENDPOINT}?dashboardId=${payload.dashboardId}`,
        payload.data
      );

      dispatch(
        addNotification({
          message: 'Successfully updated dashboard preferences',
          type: 'success',
        })
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const saveUserDefinedDashboardPreference = createAsyncThunk(
  'currentTeam/saveUserDefinedDashboardPreference',
  async (payload: DashboardPreference, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(DASHBOARD_ENDPOINT, payload);

      dispatch(
        addNotification({
          message: 'Successfully save dashboard preferences',
          type: 'success',
        })
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// V3 Dashboard
export const fetchSwimDashboardTableData = createAsyncThunk(
  'dashboard/postTableFilter',
  async (payload: PostDashboardListData, { rejectWithValue, dispatch }) => {
    try {
      let result;

      if (payload.dashboardId === 'default') {
        result = await apiPost(
          `${SWIMBETTER_ENDPOINT}/data/search?pageSize=10&pageNo=${payload.pageNo}`,
          {
            dateTimeFilter: {
              ...payload.dateTimeFilter,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            userId: payload.userId,
            // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        );
      } else {
        result = await apiPost(
          `${SWIMBETTER_ENDPOINT}/data/search?pageSize=10&pageNo=${payload.pageNo}`,
          {
            ...payload,
            dateTimeFilter: {
              ...payload.dateTimeFilter,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }
        );
      }

      return result as SwimListTable;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchProgressionChartData = createAsyncThunk(
  'dashboard/postProgressionChart',
  async (payload: PostProgressChart, { rejectWithValue, dispatch }) => {
    try {
      let result;
      if (payload.dashboardId === 'default') {
        const allSwimPayload = {
          userId: payload.userId,
          dateTimeFilter: {
            ...payload.dateTimeFilter,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        };
        result = await apiPost(PROGRESSION, allSwimPayload);
      } else {
        result = await apiPost(PROGRESSION, {
          ...payload,
          dateTimeFilter: {
            ...payload.dateTimeFilter,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
      }

      return result as ProgressionData;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const updateDashboardDateRangeSelected =
  createAction<DashboardDateRange>('dashboard/updateDateRange');

export const updateDashboardIsFavorite = createAsyncThunk(
  'dashboard/updateIsFavorite',
  async (payload: PostDashboardIsFavorite, { rejectWithValue, dispatch }) => {
    try {
      await apiPost(`${SWIMBETTER_ENDPOINT}/chart/favorite/update`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const deleteDashboardPreference = createAsyncThunk(
  'dashboard/deletePreference',
  async (dashboardId: string, { rejectWithValue, dispatch }) => {
    try {
      await apiDelete(`${DASHBOARD_ENDPOINT}?dashboardId=${dashboardId}`);

      dispatch(
        addNotification({
          message: 'Successfully delete dashboard preferences',
          type: 'success',
        })
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const updateSelectedSwimId = createAction<string>(
  'dashboard/updateSelectedSwimId'
);

export const resetSelectDashboardPreference = createAction<string>(
  'dashboard/resetSelectDashboardPreference'
);
