import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { sortArray } from '../../utils/sortArray';

const selectLicensesState = (state: RootState) => state.licenses;

export const selectOwnedLicenses = createSelector(
  selectLicensesState,
  (state) => state.ownedLicenses
);

export const selectInvitingLicenseId = createSelector(
  selectLicensesState,
  (state) => state.invitingLicenseId
);

export const selectLinkToTeamLicense = createSelector(
  selectLicensesState,
  (state) => state.linkToTeamLicense
);

export const selectAssignedLicense = createSelector(
  selectLicensesState,
  (state) => state.assignedLicense
);

export const selectShowAddLicenseToTeamModal = createSelector(
  selectLicensesState,
  (state) => state.showAddLicenseToTeamModal
);

export const selectLicenses = createSelector(
  selectOwnedLicenses,
  selectAssignedLicense,
  (ownedLicenses, assignedLicenses) => {
    const licenses = ownedLicenses.concat(assignedLicenses);
    const test = licenses.filter(
      (license, index, self) =>
        self.findIndex(
          (uniqueLicense) => uniqueLicense.licenseId === license.licenseId
        ) === index
    );

    return sortArray('serialNo', test);
  }
);

export const selectHasAssignedIndividualLicense = createSelector(
  selectAssignedLicense,
  (assignedLicenses) =>
    assignedLicenses.findIndex(
      ({ Type }) => Type === 'Gold' || Type === 'Silver'
    ) >= 0
);

export const selectLicensesErrors = createSelector(
  selectLicensesState,
  (state) => state.errors
);

export const selectLicensesIsLoading = createSelector(
  selectLicensesState,
  (state) => state.isLoading
);
