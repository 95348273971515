// ! DEPRECATED
import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { Filter, PaginationData } from '../../types/pagination';
import { ReduxState } from '../../types/redux-state';
import { Team } from '../../types/team';

import {
  acceptPendingInvite,
  cancelPendingInvite,
  declinePendingInvite,
  fetchPendingAdminCoachInvites,
  fetchPendingInviteDetails,
  fetchPendingInvitesList,
  fetchPendingTeamInvites,
  resendPendingCoachesAdminInvite,
  resendPendingInvite,
  sendCoachAdminInvites,
  sendPendingInvites,
  sendTeamInvites,
} from './actions';
import { COACH_ADMIN_INVITE } from '../../types/invite';

interface PendingInvites extends ReduxState {
  paginationData: PaginationData;
  // Filter has no use yet but I think it will have one in the near future
  teamInvites: [];
  coachAdminInvites: COACH_ADMIN_INVITE[];
  filter: Filter;
  inviteDetails: any;
  team?: Team | null;
  customErrors: any; // there are scenarios where we want to handle  component behavior based on error data from BE
}

const initialState: PendingInvites = {
  paginationData: {
    data: [],
    pageNumber: 1,
    pageSize: 25,
    totalPages: 1,
    totalRecords: 0,
  },
  teamInvites: [],
  coachAdminInvites: [],
  team: null,
  filter: {
    admin: 0,
    coach: 0,
    swimmer: 0,
  },
  inviteDetails: {
    id: '',
    email: '',
    product: {
      id: '',
    },
    team: {
      photoURL: '',
      name: '',
    },
    status: 0,
    type: 0,
  },
  isLoading: false,
  errors: [],
  customErrors: null,
};

export const pendingInvitesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPendingInviteDetails.pending, (state) => {
      state.inviteDetails = initialState.inviteDetails;
    })
    .addCase(fetchPendingInvitesList.pending, (state) => {
      state.paginationData = initialState.paginationData;
    })
    .addCase(fetchPendingInviteDetails.fulfilled, (state, { payload }) => {
      state.inviteDetails = payload;
    })
    .addCase(fetchPendingTeamInvites.fulfilled, (state, { payload }) => {
      state.teamInvites = payload;
    })
    .addCase(fetchPendingAdminCoachInvites.fulfilled, (state, { payload }) => {
      state.coachAdminInvites = payload;
    })
    .addCase(fetchPendingInvitesList.fulfilled, (state, { payload }) => {
      state.paginationData = payload?.paginationData as PaginationData;
      state.filter = payload?.filter as Filter;
    })
    .addCase(sendPendingInvites.fulfilled, (state, { payload }) => {
      state.team = payload;
      state.customErrors = null;
    })
    .addCase(resendPendingInvite.fulfilled, (state, { payload }) => {
      state.team = payload;
    })
    .addCase(sendPendingInvites.rejected, (state, { payload }) => {
      state.customErrors = payload;
    })
    .addCase(cancelPendingInvite.fulfilled, (state, { payload }) => {
      state.team = payload;
    })
    .addCase(acceptPendingInvite.fulfilled, (state, { payload }) => {
      state.team = payload;
    })
    .addMatcher(
      isAnyOf(
        acceptPendingInvite.pending,
        cancelPendingInvite.pending,
        declinePendingInvite.pending,
        fetchPendingInviteDetails.pending,
        fetchPendingInvitesList.pending,
        resendPendingInvite.pending,
        resendPendingCoachesAdminInvite.pending,
        sendTeamInvites.pending,
        sendCoachAdminInvites.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(acceptPendingInvite.fulfilled, declinePendingInvite.fulfilled),
      (state) => {
        state.inviteDetails = initialState.inviteDetails;
      }
    )
    .addMatcher(
      isAnyOf(
        acceptPendingInvite.fulfilled,
        cancelPendingInvite.fulfilled,
        declinePendingInvite.fulfilled,
        fetchPendingInviteDetails.fulfilled,
        fetchPendingInvitesList.fulfilled,
        resendPendingInvite.fulfilled,
        resendPendingCoachesAdminInvite.fulfilled,
        sendTeamInvites.fulfilled,
        sendCoachAdminInvites.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        acceptPendingInvite.rejected,
        cancelPendingInvite.rejected,
        declinePendingInvite.rejected,
        fetchPendingInviteDetails.rejected,
        fetchPendingInvitesList.rejected,
        resendPendingInvite.rejected,
        resendPendingCoachesAdminInvite.rejected,
        sendTeamInvites.rejected,
        sendCoachAdminInvites.rejected
      ),
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload as string[];
      }
    );
});
