import { createSelector } from 'reselect';

import { RootState } from '../store';

export const selectSubscriptionsState = (state: RootState) =>
  state.subscriptions;

export const selectIndividualSubscriptionsList = createSelector(
  selectSubscriptionsState,
  (state) => state.individualSubscriptionsList
);

export const selectTeamSubscriptionsList = createSelector(
  selectSubscriptionsState,
  (state) => state.teamSubscriptionsList
);

export const selectHasSelectedSubscription = createSelector(
  selectSubscriptionsState,
  (state) => state.hasSelectedSubscription
);

export const selectSubscriptionsIsLoading = createSelector(
  selectSubscriptionsState,
  (state) => state.isLoading
);

export const selectSubscriptionsErrors = createSelector(
  selectSubscriptionsState,
  (state) => state.errors
);
