import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { ReduxState } from '../../types/redux-state';
import { Subscription } from '../../types/subscription';
import {
  fetchIndividualSubscriptionsList,
  fetchTeamSubscriptionsList,
  subscribeToSubscription,
} from './actions';

interface SubscriptionsState extends ReduxState {
  individualSubscriptionsList: Subscription[];
  teamSubscriptionsList: Subscription[];
  // ! This is needed to be used in refetching
  hasSelectedSubscription: boolean;
}

const initialState: SubscriptionsState = {
  individualSubscriptionsList: [],
  teamSubscriptionsList: [],
  hasSelectedSubscription: false,
  isLoading: false,
  errors: [],
};

export const subscriptionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      fetchIndividualSubscriptionsList.fulfilled,
      (state, { payload }) => {
        state.individualSubscriptionsList = payload;
      }
    )
    .addCase(fetchTeamSubscriptionsList.fulfilled, (state, { payload }) => {
      state.teamSubscriptionsList = payload;
    })
    .addMatcher(
      isAnyOf(
        fetchIndividualSubscriptionsList.pending,
        fetchTeamSubscriptionsList.pending,
        subscribeToSubscription.pending
      ),
      (state) => {
        state.hasSelectedSubscription = false;
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        fetchIndividualSubscriptionsList.fulfilled,
        fetchTeamSubscriptionsList.fulfilled,
        subscribeToSubscription.fulfilled
      ),
      (state) => {
        state.hasSelectedSubscription = true;
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchIndividualSubscriptionsList.rejected,
        fetchTeamSubscriptionsList.rejected,
        subscribeToSubscription.rejected
      ),
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload as string[];
      }
    );
});
