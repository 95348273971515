import { createSelector } from '@reduxjs/toolkit';
import { GroupedBySwimDistance, SwimData } from '../../types/swim-data';
import { RootState } from '../store';

const selectSwimDataState = (state: RootState) => state.swimData;

export const selectCurrentSwimUser = createSelector(
  selectSwimDataState,
  (state) => state.currentSwimUser
);

export const selectedSort = createSelector(
  selectSwimDataState,
  (state) => state.selectedSort
);

export const selectedFilter = createSelector(
  selectSwimDataState,
  (state) => state.selectedFilter
);

export const selectAllSwim = createSelector(
  selectSwimDataState,
  (state) => state.allSwim
);

export const selectSwimList = createSelector(
  selectSwimDataState,
  (state) => state.swimList
);

export const selectCurrentSwimData = createSelector(
  selectSwimDataState,
  (state) => state.currentSwimData
);

export const selectCurrentSwimType = createSelector(
  selectSwimDataState,
  (state) => state.currentSwimType
);

export const selectMetersSwimList = createSelector(
  selectSwimDataState,
  (state) => state.metersSwimList
);

export const selectYardsSwimList = createSelector(
  selectSwimDataState,
  (state) => state.yardsSwimList
);

export const selectFavoriteMetersSwimList = createSelector(
  selectMetersSwimList,
  (metersSwimList) =>
    metersSwimList.map((groupedSwimData: GroupedBySwimDistance) => {
      return {
        ...groupedSwimData,
        swims: groupedSwimData.swims.filter(
          (swimData: SwimData) => swimData.isFavorite
        ),
      };
    })
);

export const selectFavoriteMetersSwimCount = createSelector(
  selectFavoriteMetersSwimList,
  (favoritesSwimList: GroupedBySwimDistance[]) =>
    favoritesSwimList.reduce(
      (currentCount, groupedSwimData: GroupedBySwimDistance) => {
        return currentCount + groupedSwimData.swims.length;
      },
      0
    )
);

export const selectFavoriteYardsSwimList = createSelector(
  selectYardsSwimList,
  (yardsSwimList) =>
    yardsSwimList.map((groupedSwimData: GroupedBySwimDistance) => {
      return {
        ...groupedSwimData,
        swims: groupedSwimData.swims.filter(
          (swimData: SwimData) => swimData.isFavorite
        ),
      };
    })
);

export const selectFavoriteYardsSwimCount = createSelector(
  selectFavoriteYardsSwimList,
  (favoritesSwimList: GroupedBySwimDistance[]) =>
    favoritesSwimList.reduce(
      (currentCount, groupedSwimData: GroupedBySwimDistance) => {
        return currentCount + groupedSwimData.swims.length;
      },
      0
    )
);

export const selectSwimDataErrors = createSelector(
  selectSwimDataState,
  (state) => state.errors
);

export const selectSwimDataIsLoading = createSelector(
  selectSwimDataState,
  (state) => state.isLoading
);

export const selectTotalStarredSwimsCount = createSelector(
  selectSwimDataState,
  (state) => state.starredSwimsCount
);
