import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import apiGet from '../../utils/api-get';
import {
  GET_VIDEO_ENDPOINT,
  PRESIGNED_APIURL_ENDPOINT,
  //UPLOAD_VIDEO_ENDPOINT,
  DELETE_VIDEO_ENDPOINT,
  UPDATE_METADATA_ENDPOINT,
  CHART_DATA_ENDPOINT,
  CLIENT_ENDPOINT,
} from '../../constants/api-endpoints';
import { catchErrors } from '../notifications';
import {
  MetaDataPayload,
  MetaDataResponse,
  ResponsePresignedURL,
  UploadVideoPayload,
  getVideoDataPayload,
  videoData,
} from '../../types/video-chart';
import apiDelete from '../../utils/api-delete';
import apiPut from '../../utils/api-put';

//to clear unwanted localstorge
export const resetState = createAction<string>('chart-video/resetState');

export const editMarkState = createAction<boolean>('chart-video/editMarkState');

export const updateSwimVideoData = createAction<MetaDataResponse>(
  'chart-video/updateSwimVideoData'
);

export const updateSeekTime = createAction<number>(
  'chart-video/updateSeekTime'
);

export const toggleNudge = createAction<boolean>('chart-video/toggleNudge');

export const setNudgeEnabledTime = createAction<number>(
  'chart-video/setNudgeEnabledTime'
);

export const fetchVideoData = createAsyncThunk(
  'videochart/getVideoData',
  async (payload: getVideoDataPayload, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiGet(
        `${CLIENT_ENDPOINT}${GET_VIDEO_ENDPOINT}${payload.sessionId}`
      );
      return result as videoData;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchPresignedURLForUpload = createAsyncThunk(
  'videochart/getPresignedURLForUpload',
  async (payload: getVideoDataPayload, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiGet(
        `${CLIENT_ENDPOINT}${PRESIGNED_APIURL_ENDPOINT}${payload.sessionId}`, //${CLIENT_ENDPOINT}
        payload
      );
      return result as ResponsePresignedURL;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const postUploadVideo = createAsyncThunk(
  'videochart/postUploadVideo',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiPut(`${payload.presignedUrl}`, payload.formData, {
        headers: {
          'Content-Type': payload.fileType,
        },
      });
      return result as any;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  'videochart/deleteVideo',
  async (payload: getVideoDataPayload, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiDelete(
        `${CLIENT_ENDPOINT}${DELETE_VIDEO_ENDPOINT}${payload.sessionId}`, //${CLIENT_ENDPOINT}
        payload
      );
      return result as any;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const updateMetadata = createAsyncThunk(
  'videochart/putUpdateMetadata',
  async (payload: MetaDataPayload, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiPut(
        `${CLIENT_ENDPOINT}${UPDATE_METADATA_ENDPOINT}${payload.sessionId}`, //${CLIENT_ENDPOINT}
        payload
      );
      return result as MetaDataResponse;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const getChartData = createAsyncThunk(
  'videochart/getChartData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiGet(`${CHART_DATA_ENDPOINT}`, payload);
      return result as any;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
