import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Product } from '../../types/product';
import apiGet from '../../utils/api-get';
import apiPost from '../../utils/api-post';

export const resetProducts = createAction('products/resetProducts');

export const fetchProductsList = createAsyncThunk(
  'products/fetchProductsList',
  async (_, { rejectWithValue }) => {
    try {
      const productsList = await apiGet('product');
      return productsList.sort(
        (firstProduct: Product, secondProduct: Product) => {
          return firstProduct.sort - secondProduct.sort;
        }
      );
    } catch (e: any) {
      throw rejectWithValue(e);
    }
  }
);

export const subscribeToProduct = createAsyncThunk(
  'products/subscribeToProduct',
  async (payload: { productId: string }, { rejectWithValue }) => {
    try {
      return await apiPost('product/subscribe', payload);
    } catch (e: any) {
      throw rejectWithValue(e);
    }
  }
);
