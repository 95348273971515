import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { addNotification, catchErrors } from '../notifications';

import apiPost from '../../utils/api-post';
import apiGet from '../../utils/api-get';
import apiDelete from '../../utils/api-delete';

import { SWIMBETTER_ENDPOINT } from '../../constants/api-endpoints';
import { Group } from '../../types/group';
import { sortArray } from '../../utils/sortArray';
import apiPatch from '../../utils/api-patch';

interface DeleteGroupProps {
  groupId: string;
  successMessage?: string;
}

const GROUP_ENDPOINT = `${SWIMBETTER_ENDPOINT}/group`;

export const resetCreatedGroup = createAction('groups/resetCreatedGroup');
export const resetResponseInfo = createAction('groups/resetResponseInfo');
export const resetGroupUsers = createAction('groups/resetGroupUsers');
export const setSelectedGroup = createAction<any>('groups/setSelectedGroup');
export const toggleAddMemberModal = createAction<any>(
  'groups/toggleAddMemberModal'
);
export const toggleDeleteGroupModal = createAction<any>(
  'groups/toggleDeleteGroupModal'
);
export const setFilteredGroups = createAction<any>('groups/setFilteredGroups');

export const fetchGroupByTeam = createAsyncThunk(
  'groups/fetchGroupByTeam',
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiGet(`${GROUP_ENDPOINT}/team?teamId=${teamId}`);
      const ungrouped: Group = result[result.length - 1];

      ungrouped.members = sortArray('firstName', ungrouped.members);

      const definedGroups: Group[] = result.filter(
        (group: any) => group.id !== '00000000-0000-0000-0000-000000000000'
      );

      definedGroups.map((group: Group) => {
        group.members = sortArray('firstName', group.members);
      });
      return [...definedGroups.reverse(), ungrouped];
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchGroup = createAsyncThunk(
  'groups/fetchGroup',
  async (
    payload: { teamId: string; groupId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await apiGet(
        `${GROUP_ENDPOINT}?teamId=${payload.teamId}&groupId=${payload.groupId}`
      );

      return result.reverse();
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchGroupUsers = createAsyncThunk(
  'groups/fetchGroupUsers',
  async (
    payload: {
      memberId: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      return await apiGet(`${GROUP_ENDPOINT}/userperteam`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async (
    payload: {
      teamId: string;
      name: string;
      successMessage?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await apiPost(GROUP_ENDPOINT, payload);
      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      response['members'] = [];
      response['id'] = `${response.groupId}`;
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const updateGroup = createAsyncThunk(
  'groups/updateGroup',
  async (
    payload: {
      groupId: string;
      name: string;
      successMessage?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await apiPatch(GROUP_ENDPOINT, payload);
      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  'groups/deleteGroup',
  async (payload: DeleteGroupProps, { rejectWithValue, dispatch }) => {
    try {
      await apiDelete(`${GROUP_ENDPOINT}?groupId=${payload.groupId}`);

      dispatch(
        addNotification({
          message: payload?.successMessage,
          type: 'success',
        })
      );
      return {
        id: null,
        name: null,
        count: null,
        members: [],
        groupId: payload.groupId,
        status: 0,
      };
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const transferUserToGroup = createAsyncThunk(
  'groups/transferUserToGroup',
  async (
    payload: {
      data: {
        memberId: string;
        oldGroupId: string;
        newGroupId: string;
      };
      successMessage: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await apiPost(
        `${GROUP_ENDPOINT}/transfer-user`,
        payload.data
      );

      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// add user to multiple groups in one api call
export const addUserToGroups = createAsyncThunk(
  'groups/addUserToGroups',
  async (
    payload: {
      data: {
        memberId: string;
        groupIds: string[];
      };
      successMessage: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await apiPost(
        `${GROUP_ENDPOINT}/groups-user`,
        payload.data
      );
      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const removeUserFromGroup = createAsyncThunk(
  'groups/removeUserFromGroup',
  async (
    payload: {
      memberId: string;
      groupId: string;
      successMessage?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await apiDelete(
        `${GROUP_ENDPOINT}/remove-member`,
        payload
      );
      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// add multiple users to one group in one api call
export const addUsersToGroup = createAsyncThunk(
  'groups/addUsersToGroup',
  async (
    payload: {
      data: {
        memberIds: any[];
        groupId: string;
      };
      successMessage: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await apiPost(
        `${GROUP_ENDPOINT}/add-member`,
        payload.data
      );
      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
