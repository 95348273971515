import { createContext, useContext, useState } from 'react';

// I choose to separate errors and showErrors so that they will be independent
// showErrors is needed for when we need to redirect into a new page and not yet
// displayed the errors in the current page
interface Errors {
  errors: string[];
  showErrors: boolean;
  addErrors: (errors: string[]) => void;
  resetErrors: () => void;
  setShowErrors: (value: boolean) => void;
}

const errorsDefaultValues = {
  errors: new Array<string>(),
  showErrors: true,
  addErrors: () => {},
  resetErrors: () => {},
  setShowErrors: () => {},
};

const ErrorsContext = createContext<Errors>(errorsDefaultValues);

export function ErrorsWrapper({ children }: any) {
  const [errors, setErrors] = useState<string[]>(errorsDefaultValues.errors);
  const [showErrors, setShowErrors] = useState<boolean>(
    errorsDefaultValues.showErrors
  );

  const addErrors = (errors: string[]) => {
    setErrors(errors);
  };

  const resetErrors = () => {
    setErrors([]);
  };

  return (
    <ErrorsContext.Provider
      value={{
        errors,
        showErrors,
        addErrors: (errors: string[]) => addErrors(errors),
        resetErrors: () => resetErrors(),
        setShowErrors,
      }}
    >
      {children}
    </ErrorsContext.Provider>
  );
}

export function useErrorsContext() {
  return useContext(ErrorsContext);
}
