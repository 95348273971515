import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import apiPost from '../../utils/api-post';
import { addNotification, catchErrors } from '../notifications';
import apiGet from '../../utils/api-get';

const SHARE_ACCESS_API = 'swim/shared-access/';

export const setSharedAcessModalState = createAction<boolean>(
  'shareAccess/setSharedAcessModalState'
);

export const sendShareAccessInvites = createAsyncThunk(
  'shareAccess/sendShareAccessInvites',
  async (invites: { email: string }[], { dispatch, rejectWithValue }) => {
    try {
      const res = await apiPost(`${SHARE_ACCESS_API}invites`, {
        sharedAccessInvites: invites,
      });

      dispatch(
        addNotification({
          message: 'Invites sent',
          type: 'success',
        })
      );
      dispatch(fetchSharedUsers());
      dispatch(setSharedAcessModalState(false));

      return res;
    } catch (e: any) {
      throw rejectWithValue(e);
    }
  }
);

export const acceptShareAccessInvite = createAsyncThunk(
  'comment/acceptShareAccessInvite',
  async (inviteId: string | string[], { rejectWithValue, dispatch }) => {
    try {
      const result = await apiPost(`${SHARE_ACCESS_API}accept`, { inviteId });
      dispatch(
        addNotification({
          message: 'Account successfully shared.',
          type: 'success',
        })
      );
      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const declineShareAccessInvite = createAsyncThunk(
  'shareAccess/declineShareAccessInvite',
  async (
    {
      inviteId,
      toEncrypt = false,
    }: { inviteId: string | string[]; toEncrypt?: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const id = toEncrypt
        ? await apiGet('data/encrypt-id', { Id: inviteId })
        : inviteId;

      const res = await apiPost(`${SHARE_ACCESS_API}decline?InviteId=${id}`);

      return res;
    } catch (e: any) {
      dispatch(
        addNotification({
          message: 'Invite link is already invalid',
          type: 'error',
        })
      );
    }
  }
);

export const fetchSharedOwners = createAsyncThunk(
  'shareAccess/fetchSharedOwners',
  async (_, { dispatch, rejectWithValue, getState }) => {
    const state: any = getState();
    try {
      const res = await apiGet(
        `${SHARE_ACCESS_API}shared-owners?userId=${state?.user?.user?.id}`
      );

      return Array.isArray(res) ? res : [];
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchSharedUsers = createAsyncThunk(
  'shareAccess/fetchSharedUsers',
  async (_, { dispatch, rejectWithValue, getState }) => {
    const state: any = getState();
    try {
      const res = await apiGet(
        `${SHARE_ACCESS_API}sharedusers?userId=${state?.user?.user?.id}`
      );
      if (Array.isArray(res)) {
        return res;
      } else {
        return [];
      }
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const removeUserAccess = createAsyncThunk(
  'shareAccess/removeUserAccess',
  async (sharedUserId: string, { dispatch, rejectWithValue, getState }) => {
    const state: any = getState();
    try {
      const res = await apiPost(
        `${SHARE_ACCESS_API}remove-user?ownerId=${state?.user?.user?.id}&sharedUserId=${sharedUserId}`
      );

      return res;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
