const sortArray = (sortBy: string, array: any[]) => {
  return array.sort((object1, object2) =>
    object1[sortBy] > object2[sortBy]
      ? 1
      : object2[sortBy] > object1[sortBy]
      ? -1
      : 0
  );
};

const sortArrayByTime = (sortBy: string, array: any[]) => {
  return array.sort(
    (object1, object2) =>
      new Date(object2[sortBy]).getTime() - new Date(object1[sortBy]).getTime()
  );
};

export { sortArray, sortArrayByTime };
