import { createReducer } from '@reduxjs/toolkit';

import {
  updateLapCount,
  updateCurrentStroke,
  updateLeftStrokeCount,
  updateRightStrokeCount,
  updateFilter,
  updateSelectedStrokes,
  updateTimeValue,
  setChartTab,
  setChartIsLoading,
  toggleVideoSync,
} from './actions';

import { SelectedStroke, Stroke } from '../../types/chart';
import { ReduxState } from '../../types/redux-state';
import { boolean } from 'yup/lib/locale';

interface ChartState extends ReduxState {
  // Current Lap of the current swim
  lapCount: number;
  leftStrokeCount: number;
  rightStrokeCount: number;
  currentStroke: Stroke;
  // Filtering what stroke should only be shown
  filter: Stroke;
  // Use in the consistency charts for strokes to show
  selectedStrokes: SelectedStroke[];
  timeValue: number;
  currentChart: string | null;
  videoSync: boolean;
}

const initialState: ChartState = {
  lapCount: 1,
  leftStrokeCount: 1,
  rightStrokeCount: 1,
  currentStroke: null,
  filter: 'Both',
  selectedStrokes: [],
  timeValue: 0,
  isLoading: false,
  currentChart: null,
  errors: [],
  videoSync: false,
};

const handleUpdateFilter = (state: ChartState, stroke: Stroke) => {
  const { filter, leftStrokeCount, rightStrokeCount } = state;

  if (stroke === 'Both') {
    state.filter = stroke;
  } else if (filter === 'None') {
    state.filter = stroke;
    state.currentStroke = stroke;
    state.leftStrokeCount = 1;
    state.rightStrokeCount = 1;
  } else if (filter === 'Both') {
    if (stroke === 'Left') {
      state.filter = 'Right';
      state.currentStroke = 'Right';
    } else {
      state.filter = 'Left';
      state.currentStroke = 'Left';
    }
  } else if (
    (stroke === 'Left' && filter === 'Right') ||
    (stroke === 'Right' && filter === 'Left')
  ) {
    const newStrokeCount =
      filter === 'Left' ? leftStrokeCount : rightStrokeCount;
    state.leftStrokeCount = newStrokeCount;
    state.rightStrokeCount = newStrokeCount;
    state.filter = 'Both';
  } else if (
    (stroke === 'Left' && filter === 'Left') ||
    (stroke === 'Right' && filter === 'Right')
  ) {
    state.filter = 'None';
    state.currentStroke = 'None';
    state.leftStrokeCount = 1;
    state.rightStrokeCount = 1;
  } else {
    state.filter = stroke;
    state.currentStroke = stroke;
  }
};

export const chartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateLapCount, (state, { payload }) => {
      state.lapCount = payload;
    })
    .addCase(updateLeftStrokeCount, (state, { payload }) => {
      state.leftStrokeCount = payload;
    })
    .addCase(updateRightStrokeCount, (state, { payload }) => {
      state.rightStrokeCount = payload;
    })
    .addCase(updateCurrentStroke, (state, { payload }) => {
      state.currentStroke = payload;
    })
    .addCase(updateFilter, (state, { payload }) => {
      handleUpdateFilter(state, payload);
    })
    .addCase(updateSelectedStrokes, (state, { payload }) => {
      state.selectedStrokes = payload;
    })
    .addCase(updateTimeValue, (state, { payload }) => {
      state.timeValue = payload;
    })
    .addCase(setChartTab, (state, { payload }) => {
      state.currentChart = payload;
    })
    .addCase(setChartIsLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(toggleVideoSync, (state) => {
      state.videoSync = !state.videoSync;
    });
});
