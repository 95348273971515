import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { GroupedBySwimDistance, SwimData } from '../../types/swim-data';

import apiGet from '../../utils/api-get';
import apiPatch from '../../utils/api-patch';
import { sortArray, sortArrayByTime } from '../../utils/sortArray';

import { catchErrors } from '../notifications';
import { RootState } from '../store';

import { SWIMBETTER_ENDPOINT } from '../../constants/api-endpoints';
import apiDelete from '../../utils/api-delete';
import apiPost from '../../utils/api-post';

const SWIM_DATA_ENDPOINT = `${SWIMBETTER_ENDPOINT}/data`;

type FetchUserSwimsPayload = {
  userId: string;
  sorting?: string;
};

type DeleteSwim = {
  swimId: string;
};

type FilterSwim = {
  filter: {
    poolDistances: any[];
    swimDistance: any[];
    time: string | number;
  };
  sort: string;
};

type Feedbacks = {
  userId: string;
  swimId: string;
  comment: string;
  optionIds: string[];
};

// Separating changing the selected swim stroke type and filtering swims based on it
// so that each action will just have one responsibility
export const changeCurrentSwimType = createAction<string>(
  'swimData/changeSelectedSwimStrokeType'
);

export const resetCurrentSwimData = createAction(
  'swimData/resetCurrentSwimData'
);

export const resetSwimList = createAction('swimData/resetSwimList');

export const setCurrentSwimUser = createAction<string>(
  'swimData/setCurrentSwimUser'
);

export const setSelectedSort = createAction<string>('swimData/setSelectedSort');

export const updateSwimFilter = createAction<any>('swimData/updateSwimFilter');

export const setCurrentSwimData = createAction<any>(
  'swimData/setCurrentSwimData'
);

export const updateStarredSwimsCount = createAction<any>(
  'swimData/updateStarredSwimsCount'
);

export const swimDataFeedbacks = createAsyncThunk(
  'teams/setSwimDataFeedbacks',
  async (payload: Feedbacks, { dispatch, rejectWithValue }) => {
    try {
      const response = apiPost(`${SWIM_DATA_ENDPOINT}/feedback`, payload);

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const filterSwimData = createAsyncThunk(
  'swimData/filterSwimData',
  async (payload: FilterSwim, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost(
        `${SWIM_DATA_ENDPOINT}/filter?sorting=${payload.sort}`,
        payload.filter
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchAllSwimData = createAsyncThunk(
  'swimData/fetchAllSwimData',
  async (payload: FetchUserSwimsPayload, { dispatch, rejectWithValue }) => {
    try {
      return await apiGet(`${SWIM_DATA_ENDPOINT}/user`, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// Separating changing the selected swim stroke type and filtering swims based on it
// so that each action will just have one responsibility
export const filterSwimList = createAsyncThunk<
  { meters: GroupedBySwimDistance[]; yards: GroupedBySwimDistance[] },
  null,
  { state: RootState }
>('swimData/filterSwimList', (_, thunkAPI) => {
  const { swimList, currentSwimType } = thunkAPI.getState().swimData;

  const filteredSwimList: SwimData[] = swimList.filter(
    (data) => currentSwimType === data.stroke
  );

  const swimsDistances = filteredSwimList.reduce(
    (
      // Choosing to use any as there is a weird error with this in TS
      swims: any,
      data
    ) => {
      const currentSwims =
        data.unitDistance === 'meters' ? swims.meters : swims.yards;
      const currentKey = data.unitDistance === 'meters' ? 'meters' : 'yards';

      const index = currentSwims.findIndex(
        (value: any) =>
          value.distance === data.distance &&
          value.unitDistance === data.unitDistance
      );
      // distance is unique
      if (index === -1) {
        currentSwims.push({
          distance: data.distance,
          unitDistance: data.unitDistance,
          swims: [data],
        });
      } else {
        currentSwims[index].swims.push(data);
      }

      return { ...swims, [currentKey]: currentSwims };
    },
    { meters: [], yards: [] }
  );
  // if (swimsDistances.meters.length > 0) {
  //   swimsDistances.meters = swimsDistances.meters.map(
  //     (data: GroupedBySwimDistance) => {
  //       return { ...data, swims: sortArrayByTime('date', data.swims) };
  //     }
  //   );
  // }

  return {
    meters: sortArray('distance', swimsDistances.meters),
    yards: sortArray('distance', swimsDistances.yards),
  };
});

export const fetchCurrentSwimData = createAsyncThunk<
  SwimData,
  string,
  { state: RootState }
>('swimData/fetchCurrentSwimData', async (id: string, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  try {
    const { swimList } = thunkAPI.getState().swimData;

    return swimList.find(({ swimId }) => swimId === id);
  } catch (e: any) {
    dispatch(catchErrors(e));
    throw rejectWithValue(e);
  }
});

export const updateSwimDataIsFavorite = createAsyncThunk<
  SwimData[],
  { swimId: string; isFavorite: boolean },
  { state: RootState }
>(
  'swimData/updateSwimDataIsFavorite',
  async (payload: { swimId: string; isFavorite: boolean }, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const { swimList } = thunkAPI.getState().swimData;
      let swimData = [...swimList];

      await apiPatch(
        `${SWIMBETTER_ENDPOINT}/chart/favorite?swimId=${payload.swimId}&isFavorite=${payload.isFavorite}`
      ).then((res) => {
        swimData = swimList.map((item) => {
          if (item.swimId === payload.swimId) {
            const nItem = { ...item };

            nItem.isFavorite = !item.isFavorite;
            return nItem;
          }
          return item;
        });

        // return swimList.map((swimData) => {
        //   if (swimData.swimId === payload.swimId) {
        //     return { ...swimData, isFavorite: payload.isFavorite };
        //   }

        //   return swimData;
        // });
      });

      return swimData;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const deleteSwimData = createAsyncThunk(
  'teams/deleteTeamMember',
  async (swimId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiDelete(
        `${SWIMBETTER_ENDPOINT}/data/?swimId=${swimId}`
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
export const fetchSwimDataById = createAsyncThunk(
  'swimData/fetchSwimDataById',
  async (swimId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet(`${SWIM_DATA_ENDPOINT}?Id=${swimId}`);

      return response[0];
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
