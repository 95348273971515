/**
 * This is different from currentTeamReducer as this is for listing of all teams
 * but currently this is only being used to store data for teams that you don't belong to.
 */
import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { resetMemberDeletedStatus } from '.';

import { ReduxState } from '../../types/redux-state';
import { Team } from '../../types/team';
import { License } from '../../types/license';
import { TeamDetails } from '../../types/team';
import {
  deleteTeamMember,
  fetchTeam,
  fetchTeamLicenses,
  fetchTeamAllocatedLicense,
  teamSelect,
  updateAvailableLicense,
  resetTeamState,
  unsetTeamAllocatedLicense,
} from './actions';

interface TeamState extends ReduxState {
  team: Team;
  availableLicense: number;
  isMemberDeleted: boolean;
  teamLicenses: License[];
  selectedTeam: TeamDetails | null;
  teamAllocatedLicenses: License[];
  teamList: TeamDetails[];
}

const initialState: TeamState = {
  isMemberDeleted: false,
  availableLicense: 0,
  team: {
    teamId: '',
    photoURL: '',
    name: '',
    productId: '',
    licensesRemaining: 0,
  },
  teamLicenses: [],
  selectedTeam: null,
  teamAllocatedLicenses: [],
  teamList: [],
  errors: [],
  isLoading: false,
};

export const teamsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTeamLicenses.fulfilled, (state, { payload }) => {
      state.teamLicenses = payload;
    })
    .addCase(fetchTeamAllocatedLicense.fulfilled, (state, { payload }) => {
      state.teamAllocatedLicenses = payload;
    })
    .addCase(fetchTeam.fulfilled, (state, { payload }) => {
      state.teamList = payload;
    })
    .addCase(teamSelect.fulfilled, (state, { payload }) => {
      state.selectedTeam = payload;
    })
    .addCase(resetMemberDeletedStatus, (state) => {
      state.isMemberDeleted = false;
    })
    .addCase(deleteTeamMember.fulfilled, (state, { payload }) => {
      state.isMemberDeleted = true;
    })
    .addCase(updateAvailableLicense, (state, { payload }) => {
      state.availableLicense = payload;
    })
    .addCase(resetTeamState, (state, { payload }) => {
      return initialState;
    })
    .addCase(unsetTeamAllocatedLicense, (state, { payload }) => {
      state.teamAllocatedLicenses = [];
    })
    .addMatcher(
      isAnyOf(
        deleteTeamMember.pending,
        fetchTeam.pending,
        fetchTeamAllocatedLicense.pending,
        fetchTeamLicenses.pending
      ),
      (state) => {
        state.errors = [];
        state.isLoading = true;
      }
    )
    .addMatcher(
      isAnyOf(
        deleteTeamMember.fulfilled,
        fetchTeam.fulfilled,
        fetchTeamAllocatedLicense.fulfilled,
        fetchTeamLicenses.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        deleteTeamMember.rejected,
        fetchTeam.rejected,
        fetchTeamAllocatedLicense.rejected,
        fetchTeamLicenses.rejected
      ),
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload as string[];
      }
    );
});
