import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { ReduxState } from '../../types/redux-state';
import {
  fetchChartNameList,
  fetchDashboardPreference,
  fetchDefaultDashboard,
  fetchProgressionCharts,
  fetchSwimDashboardData,
  fetchSwimDashboardTableData,
  fetchProgressionChartData,
  selectDashboardPreference,
  setDashboardSummaryData,
  setDefaultChartList,
  setPreferenceModalAction,
  setSelectedChart,
  toggleShowPreferenceModal,
  updateDefaultChartList,
  updateSummaryDistance,
  updateSummaryPoolType,
  updateSummaryStroke,
  updateDashboardDateRangeSelected,
  DashboardDateRange,
  updateSelectedSwimId,
  resetSelectDashboardPreference,
  toggleSideNav,
} from './actions';
import {
  ChartNameItem,
  DashboardPreference,
  DashboardSummaryItem,
  DefaultChartItem,
  ProgressionData,
  ProgressionDataItem,
  SwimListTable,
} from '../../types/dashboard';
interface DashboardState extends ReduxState {
  dashboardData: {};
  dashboardSummary: DashboardSummaryItem[];
  selectedSummary: {
    stroke: string;
    poolType: string;
    distance: string;
  };
  defaultSummary: {
    stroke: string;
    poolType: string;
    distance: string;
  } | null;
  starredSwims: number;
  defaultChartList: DefaultChartItem[];
  showPreferenceModal: boolean;
  selectedChart: DefaultChartItem | null;
  progressionData: ProgressionDataItem[];
  swimFavoriteCount: number;
  chartNameList: ChartNameItem[];
  swimtListTable: SwimListTable;
  dashboardPreferenceList: DashboardPreference[];
  selectedDashboardPreference: DashboardPreference | null;
  preferenceModalAction: 'add' | 'edit' | null;
  progressionChartData: ProgressionData;
  dashboardSelectedDateRange: DashboardDateRange;
  selectedSwimId: string;
  isOpenSideNav: boolean;
}

const initialState: DashboardState = {
  dashboardData: {},
  starredSwims: 3,
  dashboardSummary: [
    {
      id: 1,
      name: 'Force per Stroke',
      abbr: 'ForcePerStroke',
      value: 21.02,
      average: 1.1,
      units: null,
    },
    {
      id: 2,
      name: 'Stroke Rate',
      abbr: 'SR',
      value: 2.0,
      average: 1.2,
      units: 'str/min',
    },
    {
      id: 3,
      name: 'Distance per Stroke',
      abbr: null,
      value: 3.0,
      average: 1.3,
      units: null,
    },
    {
      id: 4,
      name: 'Strokes per lap',
      abbr: null,
      value: 3.0,
      average: 1.3,
      units: null,
    },
    {
      id: 5,
      name: 'Time',
      abbr: null,
      value: 3.0,
      average: 1.3,
      units: null,
    },
    {
      id: 6,
      name: 'Average Velocity',
      abbr: null,
      value: 3.0,
      average: 1.3,
      units: null,
    },
    {
      id: 7,
      name: 'Average Velocity',
      abbr: null,
      value: 3.0,
      average: 1.3,
      units: null,
    },
    {
      id: 8,
      name: 'Efficiency',
      abbr: null,
      value: 3.0,
      average: 1.3,
      units: null,
    },
  ],
  errors: [],
  selectedSummary: {
    stroke: '',
    poolType: '',
    distance: '',
  },
  isLoading: false,
  showPreferenceModal: false,
  defaultSummary: null,
  defaultChartList: [],
  selectedChart: null,
  progressionData: [],
  swimFavoriteCount: 0,
  chartNameList: [],
  swimtListTable: {
    pageNumber: 1,
    totalPages: 1,
    totalRecords: 2,
    pageSize: 10,
    hasPrevious: false,
    hasNext: false,
    data: {
      items: [],
      totalFavorites: 0,
    },
  },
  dashboardPreferenceList: [],
  selectedDashboardPreference: null,
  preferenceModalAction: null,
  progressionChartData: {
    swimFavoriteCount: 0,
    progressionResponses: [],
  },
  dashboardSelectedDateRange: {
    time: null,
    customStartDate: '',
    customEndDate: '',
    customString: '',
  },
  selectedSwimId: '',
  isOpenSideNav: true,
};

export const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleShowPreferenceModal, (state) => {
      state.showPreferenceModal = !state.showPreferenceModal;
    })
    .addCase(updateSummaryStroke, (state, { payload }) => {
      state.selectedSummary.stroke = payload;
    })
    .addCase(updateSummaryPoolType, (state, { payload }) => {
      state.selectedSummary.poolType = payload;
    })
    .addCase(selectDashboardPreference, (state, { payload }) => {
      state.selectedDashboardPreference = payload;
    })
    .addCase(setPreferenceModalAction, (state, { payload }) => {
      state.preferenceModalAction = payload;
    })
    .addCase(updateSummaryDistance, (state, { payload }) => {
      state.selectedSummary.distance = payload;
    })
    .addCase(setSelectedChart, (state, { payload }) => {
      state.selectedChart = payload;
    })
    .addCase(setDefaultChartList, (state, { payload }) => {
      state.defaultChartList = payload;
    })
    .addCase(setDashboardSummaryData.fulfilled, (state, { payload }) => {
      state.dashboardSummary = payload;
    })
    .addCase(updateDefaultChartList, (state, { payload }) => {
      state.defaultChartList = payload;
    })
    .addCase(fetchSwimDashboardData.fulfilled, (state, { payload }) => {
      state.dashboardData = payload;
    })
    .addCase(fetchDefaultDashboard.fulfilled, (state, { payload }) => {
      if (payload?.chartOptions.length > 0) {
        state.defaultChartList = payload.chartOptions.sort(
          (a: any, b: any) => a.sequence - b.sequence
        );
      }
      if (payload?.Stroke !== '0') {
        state.defaultSummary = {
          stroke: payload.Stroke,
          poolType: payload.PoolDistance,
          distance: payload.distance,
        };
      }
    })
    .addCase(fetchProgressionCharts.fulfilled, (state, { payload }) => {
      state.progressionData = payload.progressionResponses;
      state.swimFavoriteCount = payload.swimFavoriteCount;
    })
    .addCase(fetchChartNameList.fulfilled, (state, { payload }) => {
      state.chartNameList = payload;
    })
    .addCase(fetchDashboardPreference.fulfilled, (state, { payload }) => {
      state.dashboardPreferenceList = payload;
    })
    .addCase(fetchProgressionCharts.rejected, (state) => {
      state.progressionData = [];
    })
    .addCase(fetchSwimDashboardTableData.fulfilled, (state, { payload }) => {
      state.swimtListTable = payload;
    })
    .addCase(fetchProgressionChartData.fulfilled, (state, { payload }) => {
      state.progressionChartData = payload;
    })
    .addCase(updateDashboardDateRangeSelected, (state, { payload }) => {
      state.dashboardSelectedDateRange = payload;
    })
    .addCase(updateSelectedSwimId, (state, { payload }) => {
      state.selectedSwimId = payload;
    })
    .addCase(toggleSideNav, (state) => {
      state.isOpenSideNav = !state.isOpenSideNav;
    })
    .addCase(resetSelectDashboardPreference, (state) => {
      state.selectedDashboardPreference = null;
      state.dashboardPreferenceList = [];
    })
    .addMatcher(isAnyOf(fetchProgressionCharts.pending), (state) => {
      state.isLoading = true;
    });
});
