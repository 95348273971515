import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  fetchGroupUsers,
  createGroup,
  transferUserToGroup,
  addUserToGroups,
  removeUserFromGroup,
  addUsersToGroup,
  resetCreatedGroup,
  deleteGroup,
  resetResponseInfo,
  resetGroupUsers,
  setSelectedGroup,
  toggleAddMemberModal,
  fetchGroupByTeam,
  toggleDeleteGroupModal,
  fetchGroup,
  setFilteredGroups,
} from './actions';
import { Group } from '../../types/group';
import { ReduxState } from '../../types/redux-state';

interface GroupState extends ReduxState {
  group?: Group;
  groups?: Group[];
  addMemberModal: boolean;
  deleteGroupModal: boolean;
  responseInfo: any;
  filteredGroups: string[];
  hasMutation: boolean;
}

const initialState: GroupState = {
  group: null,
  groups: [],
  addMemberModal: false,
  deleteGroupModal: false,
  responseInfo: null,
  isLoading: false,
  errors: [],
  filteredGroups: [],
  hasMutation: false,
};

export const groupsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetGroupUsers, (state) => {
      state.groups = [];
    })
    .addCase(resetCreatedGroup, (state) => {
      state.group = null;
    })
    .addCase(resetResponseInfo, (state) => {
      state.responseInfo = null;
    })
    .addCase(toggleAddMemberModal, (state, { payload }) => {
      state.addMemberModal = payload;
    })
    .addCase(setFilteredGroups, (state, { payload }) => {
      state.filteredGroups = payload;
    })
    .addCase(toggleDeleteGroupModal, (state, { payload }) => {
      state.deleteGroupModal = payload;
    })
    .addCase(setSelectedGroup, (state, { payload }) => {
      state.group = payload;
    })
    .addCase(fetchGroupByTeam.fulfilled, (state, { payload }) => {
      state.groups = [...payload];
      state.hasMutation = false;
    })
    .addCase(fetchGroupUsers.fulfilled, (state, { payload }) => {
      state.groups = [...payload];
    })
    .addCase(createGroup.fulfilled, (state, { payload }) => {
      state.group = payload;
    })
    .addCase(fetchGroup.fulfilled, (state, { payload }) => {
      state.group = payload;
    })
    .addCase(deleteGroup.fulfilled, (state, { payload }) => {
      state.group = payload;
    })
    .addCase(transferUserToGroup.fulfilled, (state, { payload }) => {
      state.responseInfo = payload;
    })
    .addCase(addUserToGroups.fulfilled, (state, { payload }) => {
      state.responseInfo = payload;
    })
    .addCase(removeUserFromGroup.fulfilled, (state, { payload }) => {
      state.responseInfo = payload;
      state.hasMutation = true;
    })
    .addCase(addUsersToGroup.fulfilled, (state, { payload }) => {
      state.responseInfo = payload;
    })
    .addMatcher(
      isAnyOf(
        fetchGroupUsers.pending,
        transferUserToGroup.pending,
        addUserToGroups.pending,
        createGroup.pending,
        removeUserFromGroup.pending,
        addUsersToGroup.pending,
        deleteGroup.pending
      ),
      (state) => {
        state.isLoading = true;
      }
    )
    .addMatcher(
      isAnyOf(
        deleteGroup.fulfilled,
        deleteGroup.rejected,
        fetchGroupUsers.fulfilled,
        fetchGroupUsers.rejected,
        transferUserToGroup.fulfilled,
        transferUserToGroup.rejected,
        addUserToGroups.fulfilled,
        addUserToGroups.rejected,
        removeUserFromGroup.fulfilled,
        removeUserFromGroup.rejected,
        addUsersToGroup.fulfilled,
        addUsersToGroup.rejected,
        createGroup.fulfilled,
        createGroup.rejected
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchGroupUsers.rejected,
        createGroup.rejected,
        transferUserToGroup.rejected,
        addUserToGroups.rejected,
        removeUserFromGroup.rejected,
        addUsersToGroup.rejected,
        deleteGroup.rejected,
        createGroup.rejected
      ),
      (state, { payload }) => {
        state.errors = payload as string[];
        state.responseInfo = null;
      }
    );
});
