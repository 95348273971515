import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { INVITE_ENDPOINT } from '../../constants/api-endpoints';
import { Status } from '../../enums/status';
import { USER_STRING_TYPES } from '../../enums/user';
import { Invite } from '../../types/invite';
import apiGet from '../../utils/api-get';
import apiPost from '../../utils/api-post';
import { addNotification, catchErrors } from '../notifications';

const INVITES_TYPE = 'invites';

interface SendInviteProps {
  email: string;
  type: USER_STRING_TYPES;
  licenseId: string;
}

interface AcceptOrDeclineInviteProps {
  inviteId: string;
  toEncrypt?: boolean;
}

export const updateShowDuplicateInviteModal = createAction<boolean>(
  `${INVITES_TYPE}/updateShowDuplicateInviteModal`
);

export const fetchInvites = createAsyncThunk(
  `${INVITES_TYPE}/fetchInvites`,
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      const invites: Invite[] = await apiGet(
        `${INVITE_ENDPOINT}/invites-by-email`,
        {
          email,
        }
      );

      return invites.filter(({ status }) => status === Status.Active);
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const fetchSentInvites = createAsyncThunk(
  `${INVITES_TYPE}/fetchSentInvites`,
  async (inviterId: string, { dispatch, rejectWithValue }) => {
    try {
      return await apiGet(`${INVITE_ENDPOINT}/get-by-inviter`, {
        InviterId: inviterId,
      });
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const sendInvite = createAsyncThunk(
  `${INVITES_TYPE}/sendInvite`,
  async (params: SendInviteProps, { dispatch, rejectWithValue }) => {
    try {
      await apiPost(`${INVITE_ENDPOINT}/individual-single`, params);

      dispatch(
        addNotification({
          message: 'Invitation sent',
          type: 'success',
        })
      );
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const cancelInvite = createAsyncThunk(
  `${INVITES_TYPE}/cancelInvite`,
  async (inviteId: string, { dispatch, rejectWithValue }) => {
    try {
      await apiPost(
        `${INVITE_ENDPOINT}/individual-cancel?InviteId=${inviteId}`
      );

      dispatch(
        addNotification({
          message: 'Invitation successfully cancelled',
          type: 'success',
        })
      );
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const acceptInvite = createAsyncThunk(
  `${INVITES_TYPE}/acceptInvite`,
  async (
    { inviteId, toEncrypt = false }: AcceptOrDeclineInviteProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const id = toEncrypt
        ? await apiGet('data/encrypt-id', { Id: inviteId })
        : inviteId;

      const response = await apiPost(`${INVITE_ENDPOINT}/individual-accept`, {
        inviteId: id,
      });

      dispatch(
        addNotification({
          message: 'Invitation successfully accepted',
          type: 'success',
        })
      );

      return response;
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const declineInvite = createAsyncThunk(
  `${INVITES_TYPE}/declineInvite`,
  async (
    { inviteId, toEncrypt = false }: AcceptOrDeclineInviteProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const id = toEncrypt
        ? await apiGet('data/encrypt-id', { Id: inviteId })
        : inviteId;

      const response = await apiPost(
        `${INVITE_ENDPOINT}/decline?InviteId=${id}`
      );

      dispatch(
        addNotification({
          message: 'Invitation successfully declined',
          type: 'success',
        })
      );

      return response;
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const checkHasAccount = createAsyncThunk(
  `checkHasAccount/checkInviteId`,
  async (inviteId: string | string[], { dispatch, rejectWithValue }) => {
    try {
      const res = await apiGet(`swim/invite/has-account?inviteId=${inviteId}`, {
        inviteId,
      });

      return res;
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);
