import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectProfileState = (state: RootState) => state.profile;

export const selectProfile = createSelector(
  selectProfileState,
  (state) => state.profile
);

export const selectProfileErrors = createSelector(
  selectProfileState,
  (state) => state.errors
);

export const selectProfileIsLoading = createSelector(
  selectProfileState,
  (state) => state.isLoading
);
