import { createReducer } from '@reduxjs/toolkit';
import {
  fetchSharedOwners,
  fetchSharedUsers,
  setSharedAcessModalState,
} from './actions';

interface Swimmer {
  email: string;
  firstName: string;
  lastName: string;
  webStoreId: string;
  id: string;
}

type SharedAccessState = {
  modalIsOpen: boolean;
  swimmers: Swimmer[];
  sharedUsers: Swimmer[];
};

const initialState: SharedAccessState = {
  modalIsOpen: false,
  swimmers: [],
  sharedUsers: [],
};

export const shareAccessReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSharedAcessModalState, (state, { payload }) => {
      state.modalIsOpen = payload;
    })
    .addCase(fetchSharedOwners.fulfilled, (state, { payload }) => {
      state.swimmers = payload;
    })
    .addCase(fetchSharedUsers.fulfilled, (state, { payload }) => {
      state.sharedUsers = payload;
    });
});
