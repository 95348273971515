import { createReducer } from '@reduxjs/toolkit';

import { Notification } from '../../types/notification';
import { addNotification, catchErrors, removeNotification } from './actions';

type NotificationState = {
  notifications: Notification[];
};

const initialState: NotificationState = {
  notifications: [],
};

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addNotification, (state, { payload }) => {
      state.notifications = [...state.notifications, payload];
    })
    .addCase(removeNotification, (state, { payload }) => {
      state.notifications.splice(payload, 1);
    })
    .addCase(catchErrors, (state, { payload }) => {
      state.notifications = [...state.notifications, ...payload];
    });
});
