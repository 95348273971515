import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { SWIM_TYPES } from '../../constants/swim-types';

import { ReduxState } from '../../types/redux-state';
import { GroupedBySwimDistance, SwimData } from '../../types/swim-data';
import {
  fetchCurrentSwimData,
  changeCurrentSwimType,
  fetchAllSwimData,
  filterSwimList,
  resetCurrentSwimData,
  updateSwimDataIsFavorite,
  filterSwimData,
  updateSwimFilter,
  setSelectedSort,
  swimDataFeedbacks,
  setCurrentSwimUser,
  resetSwimList,
  updateStarredSwimsCount,
  fetchSwimDataById,
  setCurrentSwimData,
} from './actions';

interface SwimDataState extends ReduxState {
  allSwim: SwimData[];
  swimList: SwimData[];
  metersSwimList: GroupedBySwimDistance[];
  yardsSwimList: GroupedBySwimDistance[];
  currentSwimType: string;
  currentSwimData: SwimData;
  selectedFilter: {
    poolDistances: any[];
    swimDistance: any[];
    time: string;
  };
  selectedSort: string;
  currentSwimUser: string;
  starredSwimsCount: number;
}

const initialState: SwimDataState = {
  currentSwimUser: '',
  allSwim: [],
  swimList: [],
  metersSwimList: [],
  yardsSwimList: [],
  currentSwimType: SWIM_TYPES.FREESTYLE.name,
  currentSwimData: {
    swimId: '',
    memberType: 0,
    stroke: 'Freestyle',
    distance: 0,
    unitDistance: 'meters',
    duration: 0,
    locationId: '',
    poolName: '',
    poolDistance: '',
    laps: 0,
    totalStrokesLeft: 0,
    totalStrokesRight: 0,
    avgStrokesPerLap: 0,
    avgVelocity: 0,
    peakVelocity: 0,
    efficiency: 0,
    avgDps: 0,
    avgFps: 0,
    avgStrokeRate: 0,
    isFavorite: false,
    readView: '',
    commentView: '0',
    t0_trim: 0,
    dataProcessCode: 2,
  },
  isLoading: false,
  errors: [],
  selectedFilter: {
    poolDistances: [],
    swimDistance: [],
    time: '',
  },
  selectedSort: 'latest',
  starredSwimsCount: 0,
};

export const swimDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeCurrentSwimType, (state, { payload }) => {
      state.currentSwimType = payload;
    })
    .addCase(resetSwimList, (state) => {
      state.allSwim = initialState.allSwim;
      state.swimList = initialState.swimList;
      state.metersSwimList = initialState.metersSwimList;
      state.yardsSwimList = initialState.yardsSwimList;
    })
    .addCase(resetCurrentSwimData, (state) => {
      state.currentSwimData = initialState.currentSwimData;
    })
    .addCase(setSelectedSort, (state, { payload }) => {
      state.selectedSort = payload;
    })
    .addCase(setCurrentSwimUser, (state, { payload }) => {
      state.currentSwimUser = payload;
    })
    .addCase(updateSwimFilter, (state, { payload }) => {
      state.selectedFilter = payload;
    })
    .addCase(updateStarredSwimsCount, (state, { payload }) => {
      state.starredSwimsCount = payload;
    })
    .addCase(fetchCurrentSwimData.fulfilled, (state, { payload }) => {
      state.currentSwimData = payload;
    })
    .addCase(fetchAllSwimData.fulfilled, (state, { payload }) => {
      state.allSwim = payload;
      state.swimList = payload;
    })
    .addCase(filterSwimData.fulfilled, (state, { payload }) => {
      state.swimList = payload;
    })
    .addCase(filterSwimList.fulfilled, (state, { payload }) => {
      state.metersSwimList = payload.meters;
      state.yardsSwimList = payload.yards;
    })
    .addCase(updateSwimDataIsFavorite.fulfilled, (state, { payload }) => {
      state.swimList = payload;
    })
    .addCase(fetchSwimDataById.fulfilled, (state, { payload }) => {
      state.currentSwimData = payload;
    })
    .addCase(setCurrentSwimData, (state, { payload }) => {
      state.currentSwimData = payload;
    })
    .addCase(fetchAllSwimData.rejected, (state) => {
      state.swimList = [];
    })
    .addCase(filterSwimList.rejected, (state) => {
      state.metersSwimList = [];
      state.yardsSwimList = [];
    })
    .addMatcher(
      isAnyOf(
        fetchAllSwimData.pending,
        fetchCurrentSwimData.pending,
        filterSwimList.pending,
        updateSwimDataIsFavorite.pending,
        swimDataFeedbacks.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        fetchAllSwimData.fulfilled,
        fetchCurrentSwimData.fulfilled,
        filterSwimList.fulfilled,
        updateSwimDataIsFavorite.fulfilled,
        swimDataFeedbacks.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchAllSwimData.rejected,
        fetchCurrentSwimData.rejected,
        filterSwimList.rejected,
        updateSwimDataIsFavorite.rejected,
        swimDataFeedbacks.rejected
      ),
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload as string[];
      }
    );
});
