import { createAsyncThunk } from '@reduxjs/toolkit';

import { Subscription } from '../../types/subscription';
import apiGet from '../../utils/api-get';
import apiPost from '../../utils/api-post';
import { catchErrors } from '../notifications';

type FetchProps = {
  productId: string;
};

type SubcribeToProps = {
  subscriptionPriceId: string;
  licenseQuantity?: number;
};

export const fetchIndividualSubscriptionsList = createAsyncThunk(
  'subscriptions/fetchIndividualSubscriptionsList',
  async (payload: FetchProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet('subscription/individual', payload);

      return response.sort(
        (firstSubscription: Subscription, secondSubscription: Subscription) => {
          return firstSubscription.sort - secondSubscription.sort;
        }
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchTeamSubscriptionsList = createAsyncThunk(
  'subscriptions/fetchTeamSubscriptionsList',
  async (payload: FetchProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet('subscription/team', payload);

      return response.sort(
        (firstSubscription: Subscription, secondSubscription: Subscription) => {
          return firstSubscription.sort - secondSubscription.sort;
        }
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const subscribeToSubscription = createAsyncThunk(
  'subscriptions/subscribeToSubscription',
  async (payload: SubcribeToProps, { dispatch, rejectWithValue }) => {
    try {
      return await apiPost('subscription/subscribe', payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
