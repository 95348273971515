import { Status } from '../enums/status';
import { License } from '../types/license';

export const computeRemainingValidity = (
  license: License,
  currentDate: Date
): License => {
  const expiry = license?.expiryDate
    ? new Date(license.expiryDate).setHours(0, 0, 0, 0)
    : 0;

  // (24 * 60 * 60 * 1000) is actually 24hrs * 60 mins * 60seconds * 1000 milliseconds
  // Dividing the result of subtracting the expiry in ms with the current time in ms
  // basically converts milliseconds to days
  const remainingDays =
    expiry === 0
      ? 0
      : Math.floor(
          (new Date(expiry).getTime() - currentDate.getTime()) /
            (24 * 60 * 60 * 1000)
        );

  return {
    ...license,
    remainingDays: remainingDays < 0 ? 0 : remainingDays,
    Status: !license?.expiryDate ? Status.Inactive : license.Status,
  };
};
