export const SWIM_TYPES = {
  FREESTYLE: {
    name: 'Freestyle',
    nickname: 'Free',
  },
  BACKSTROKE: {
    name: 'Backstroke',
    nickname: 'Back',
  },
  BREASTSTROKE: {
    name: 'Breaststroke',
    nickname: 'Breast',
  },
  BUTTERFLY: {
    name: 'Butterfly',
    nickname: 'Fly',
  },
};
