import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { License, LinkToTeamDetails } from '../../types/license';
import { ReduxState } from '../../types/redux-state';

import {
  assignedSelfToLicense,
  cancelLicense,
  fetchAssignedLicenses,
  fetchOwnedLicenses,
  setLinkToTeamLicense,
  unassignLicense,
  updateInvitingLicenseId,
  updateShowAddLicenseToTeamModal,
} from './actions';
import { Status } from '../../enums/status';

interface LicensesState extends ReduxState {
  assignedLicense: License[];
  ownedLicenses: License[];
  invitingLicenseId: string;
  showAddLicenseToTeamModal: boolean;
  linkToTeamLicense: LinkToTeamDetails;
}

const initialState: LicensesState = {
  assignedLicense: [],
  ownedLicenses: [],
  invitingLicenseId: '',
  showAddLicenseToTeamModal: false,
  errors: [],
  isLoading: false,
  linkToTeamLicense: {
    serialNo: '',
    licenseId: '',
    name: '',
    email: '',
    userId: '',
  },
};

export const licensesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateShowAddLicenseToTeamModal, (state, { payload }) => {
      state.showAddLicenseToTeamModal = payload;
    })
    .addCase(updateInvitingLicenseId, (state, { payload }) => {
      state.invitingLicenseId = payload;
    })
    .addCase(setLinkToTeamLicense, (state, { payload }) => {
      state.linkToTeamLicense = payload;
    })
    .addCase(fetchOwnedLicenses.fulfilled, (state, { payload }) => {
      state.ownedLicenses = payload;
    })
    .addCase(fetchAssignedLicenses.fulfilled, (state, { payload }) => {
      state.assignedLicense = payload;
    })
    .addMatcher(
      isAnyOf(
        assignedSelfToLicense.pending,
        fetchAssignedLicenses.pending,
        fetchOwnedLicenses.pending,
        unassignLicense.pending,
        cancelLicense.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        assignedSelfToLicense.fulfilled,
        fetchAssignedLicenses.fulfilled,
        fetchOwnedLicenses.fulfilled,
        unassignLicense.fulfilled,
        cancelLicense.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        assignedSelfToLicense.rejected,
        fetchAssignedLicenses.rejected,
        fetchOwnedLicenses.rejected,
        unassignLicense.rejected,
        cancelLicense.rejected
      ),
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload as string[];
      }
    );
});
