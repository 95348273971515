import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectJoinRequestsState = (state: RootState) => state.joinRequests;

export const selectJoinRequestsPaginationData = createSelector(
  selectJoinRequestsState,
  (state) => state.paginationData
);

export const selectJoinRequests = createSelector(
  selectJoinRequestsState,
  (state) => state.joinRequest
);

export const selectJoinRequestsIsLoading = createSelector(
  selectJoinRequestsState,
  (state) => state.isLoading
);

export const selectSuccessStatus = createSelector(
  selectJoinRequestsState,
  (state) => state.isSuccess
);
