import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { Invite } from '../../types/invite';
import { ReduxState } from '../../types/redux-state';
import {
  acceptInvite,
  cancelInvite,
  declineInvite,
  fetchInvites,
  fetchSentInvites,
  sendInvite,
  updateShowDuplicateInviteModal,
} from './actions';

interface InvitesState extends ReduxState {
  invites: Invite[];
  sentInvites: Invite[];
  showDuplicateInviteModal: boolean;
}

const initialState: InvitesState = {
  invites: [],
  sentInvites: [],
  showDuplicateInviteModal: false,
  errors: [],
  isLoading: false,
};

export const invitesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateShowDuplicateInviteModal, (state, { payload }) => {
      state.showDuplicateInviteModal = payload;
    })
    .addCase(fetchInvites.fulfilled, (state, { payload }) => {
      state.invites = payload;
    })
    .addCase(fetchSentInvites.fulfilled, (state, { payload }) => {
      state.sentInvites = payload;
    })
    .addMatcher(
      isAnyOf(
        acceptInvite.pending,
        declineInvite.pending,
        fetchInvites.pending,
        fetchSentInvites.pending,
        cancelInvite.pending,
        sendInvite.pending
      ),
      (state) => {
        state.isLoading = true;
        state.errors = [];
      }
    )
    .addMatcher(
      isAnyOf(
        acceptInvite.fulfilled,
        declineInvite.fulfilled,
        fetchInvites.fulfilled,
        fetchSentInvites.fulfilled,
        cancelInvite.fulfilled,
        sendInvite.fulfilled
      ),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        acceptInvite.rejected,
        declineInvite.rejected,
        fetchInvites.rejected,
        fetchSentInvites.rejected,
        cancelInvite.rejected,
        sendInvite.rejected
      ),
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload as string[];
      }
    );
});
