// TODO: Remove unrelated to currentTeam actions to another file
//       For example, updateTeam and createTeam are not really related to the current team
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  SWIMBETTER_ENDPOINT,
  USER_ENDPOINT,
} from '../../constants/api-endpoints';
import { USER_TYPE } from '../../constants/user';

import { Group } from '../../types/group';
import { Location } from '../../types/location';
import { Team, TeamMember } from '../../types/team';
import { Member, User } from '../../types/user';

import apiGet from '../../utils/api-get';
import apiPatch from '../../utils/api-patch';
import apiPost from '../../utils/api-post';
import { fetchLocalStorageData } from '../../utils/localstorage';
import { sortArray } from '../../utils/sortArray';

import { addNotification, catchErrors } from '../notifications';
import { RootState } from '../store';
import apiDelete from '../../utils/api-delete';

const TEAM_ENDPOINT = `${SWIMBETTER_ENDPOINT}/team`;
const TEAM_LICENSES_ENDPOINT = `${SWIMBETTER_ENDPOINT}/license/team`;
const TEAM_LOCATION = `${SWIMBETTER_ENDPOINT}/location`;

type FetchCurrentTeamProps = {
  teamId: string;
  role?: number;
};

type CreateTeamLocationData = {
  data: {
    locationAddMultiples: Location[];
    locationOwner: string;
  };
  successMessage: string;
};

type UpdateTrainingLocation = {
  data: Location;
  successMessage: string;
};

type UnlinkToTeamData = {
  teamId: string;
  userId: string;
  memberType: string;
  successMessage: string;
};

export const resetCurrentTeamGroups = createAction(
  'currentTeam/resetCurrentTeamGroups'
);
export const resetCurrentTeamSelectedMember = createAction(
  'currentTeam/resetCurrentTeamSelectedMember'
);
export const resetTeamRole = createAction('currentTeam/resetTeamRole');

// export const setSelectedMember = createAction<TeamMember>(
//   'currentTeam/setSelectedMember'
// );

export const setSelectedSwimmer = createAsyncThunk(
  'teams/setSelectedSwimmer',
  async (payload: Member | null, { dispatch, rejectWithValue }) => {
    try {
      return payload;
    } catch (e: any) {
      dispatch(catchErrors(e));
      rejectWithValue(e);
    }
  }
);

export const manualSetTeam = createAction(
  'currentTeam/manualSetTeam',
  (team): { payload: { team: Team } } => {
    return {
      payload: team,
    };
  }
);

export const saveCurrentTeamLocations = createAsyncThunk(
  'currentTeam/saveCurrentTeamLocations',
  async (payload: CreateTeamLocationData, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiPost(
        `${TEAM_LOCATION}/save-multiple-locations`,
        payload.data
      );

      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const deleteTrainingLocation = createAsyncThunk(
  'currentTeam/deleteTrainingLocation',
  async (location: Location, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiDelete(
        `${TEAM_LOCATION}?Id=${location.locationId}`
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchCurrentTeamLocations = createAsyncThunk(
  'currentTeam/fetchCurrentTeamLocations',
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      return await apiGet(`${TEAM_LOCATION}/owner?Id=${teamId}`);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchCurrentTeamAllocatedLicense = createAsyncThunk(
  'currentTeam/fetchAllocateLicenses',
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      return await apiGet(`${TEAM_LICENSES_ENDPOINT}/?teamId=${teamId}`);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const updateTrainingLocation = createAsyncThunk(
  'currentTeam/updateTrainingLocation',
  async (payload: UpdateTrainingLocation, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPatch(TEAM_LOCATION, payload.data);

      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchCurrentTeam = createAsyncThunk(
  'currentTeam/fetchCurrentTeam',
  async (
    { teamId, role }: FetchCurrentTeamProps,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await apiGet(`${TEAM_ENDPOINT}?teamId=${teamId}`);

      return result;

      // if (teamId) {
      //   const team = await apiGet(`${TEAM_ENDPOINT}/team?teamId=${teamId}`);

      //   if (!team.teamId) {
      //     return { team: { id: teamId, name: '', photoURL: '' }, role };
      //   } else {
      //     return { team, role };
      //   }
      // } else {
      //   return {
      //     team: {
      //       id: '',
      //       name: 'Personal (Individual Subscription)',
      //       photoURL: '',
      //     },
      //     role: USER_TYPE.SWIMMER,
      //   };
      // }
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchCurrentTeamCoaches = createAsyncThunk(
  'currentTeam/fetchCurrentTeamCoaches',
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiGet(`${TEAM_ENDPOINT}/coaches?teamId=${teamId}`);

      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// TODO: Make teamId optional
export const fetchCurrentTeamGroups = createAsyncThunk(
  'currentTeam/fetchCurrentTeamGroups',
  async (
    payload: {
      teamId: string;
      swimmer: number;
      coach: number;
      admin: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const groups: Group[] = await apiGet(`${TEAM_ENDPOINT}/group`, payload);
      // make sure that coaches are first on the lists
      groups.forEach((group) => {
        if (group.members.length > 0) {
          const sortedMembersByType = sortArray('type', group.members);
          group.members = sortedMembersByType;
        }
      });

      // after sorting by type
      // we need to sort all coach and swimmer by firstName
      // but sort them by all coach and by all swimmer
      groups.forEach((group) => {
        if (group.members.length > 0) {
          let coachMembers: Member[] = [];
          let swimmerMembers: Member[] = [];
          group.members.forEach((member) => {
            if (member.type === USER_TYPE.COACH) {
              coachMembers.push(member);
            } else {
              swimmerMembers.push(member);
            }
          });
          // sort by firstName
          coachMembers = [...sortArray('firstName', coachMembers)];
          swimmerMembers = [...sortArray('firstName', swimmerMembers)];

          group.members = [...coachMembers.concat(swimmerMembers)];
        }
      });

      const defaultGroupIndex = groups.findIndex(
        (group: Group) => group.id === 'default'
      );

      const defaultGroup = groups[defaultGroupIndex];

      if (defaultGroupIndex >= 0) {
        groups.splice(defaultGroupIndex, 1);
      }

      return {
        groups,
        defaultGroup,
        groupFilter: {
          admin: payload.admin,
          coach: payload.coach,
          swimmer: payload.swimmer,
        },
      };
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// TODO: Make teamId optional
export const fetchCurrentTeamMembers = createAsyncThunk(
  'currentTeam/fetchTeamMembers',
  async (teamId: string, { dispatch, rejectWithValue }) => {
    try {
      const teamMembers = await apiGet(
        `${TEAM_ENDPOINT}/member?teamId=${teamId}`
      );

      const admins = teamMembers.filter(
        (teamMember: Member) => teamMember.type === USER_TYPE.ADMIN
      );
      const coaches = teamMembers.filter(
        (teamMember: Member) => teamMember.type === USER_TYPE.COACH
      );
      const swimmers = teamMembers.filter(
        (teamMember: Member) => teamMember.type === USER_TYPE.SWIMMER
      );

      return { admins, coaches, swimmers };
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// export const fetchCurrentTeamSelectedMember = createAsyncThunk<
//   TeamMember,
//   string,
//   { state: RootState }
// >('currentTeam/fetchSelectedMember', async (userId: string, thunkAPI) => {
//   const { dispatch, rejectWithValue } = thunkAPI;
//   try {
//     const user: User = await apiGet(USER_ENDPOINT, { userId });
//     const currentTeamId = thunkAPI.getState().currentTeam.team.teamId;

//     const roles = user.member
//       .filter((member) => member.teamId === currentTeamId)
//       .map((member) => member.type);

//     return { ...user, roles };
//   } catch (e: any) {
//     dispatch(catchErrors(e));
//     rejectWithValue(e);
//   }
// });

export const fetchCurrentTeamSelectedGroup = createAsyncThunk<
  Group,
  string,
  { state: RootState }
>(
  'currentTeam/fetchCurrentTeamSelectedGroup',
  async (payload: string, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const groups = thunkAPI.getState().currentTeam.groups;

      const group = groups.find(({ id }) => id === payload);
      if (!group && payload !== 'default') {
        dispatch(
          addNotification({
            message: 'Group not found.',
            type: 'error',
          })
        );
      }

      return { ...group };
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

// This is needed so we won't need to supply the license in the payload anymore
// And some of its sample usage is when after sending a pending invite we need to
// know how many licenses are available for the team
export const refetchCurrentTeam = createAsyncThunk<
  Team,
  null,
  { state: RootState }
>('currentTeam/refetchCurrentTeam', async (_, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  try {
    const response = await apiGet(TEAM_ENDPOINT, {
      teamId: thunkAPI.getState().currentTeam.team.teamId,
    });
    return response;
  } catch (e: any) {
    dispatch(catchErrors(e));
    throw rejectWithValue(e);
  }
});

// This is needed for the sidebar so that after updating the team groups it will reflect here
// without the need to send the filters again.
export const refetchCurrentTeamGroups = createAsyncThunk<
  Group[],
  null,
  { state: RootState }
>('currentTeam/refetchCurrentTeamGroups', async (_, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  try {
    const { team, groupFilter } = thunkAPI.getState().currentTeam;

    return await apiGet(`${TEAM_ENDPOINT}/group`, {
      ...groupFilter,
      teamId: team.teamId,
    });
  } catch (e: any) {
    dispatch(catchErrors(e));
    throw rejectWithValue(e);
  }
});

// Stored means local storage, I just didn't name it fetchLocalStorageCurrentTeam as
// it is long and you might change where we store it in the future
export const fetchStoredCurrentTeam = createAsyncThunk(
  'currentTeam/fetchStoredCurrentTeam',
  async (_, { dispatch, rejectWithValue }) => {
    const { currentTeam } = fetchLocalStorageData('state');
    if (currentTeam) {
      return currentTeam;
    } else {
      dispatch(
        addNotification({
          message: 'No stored current team data',
          type: 'error',
        })
      );
      throw rejectWithValue('No stored current team data');
    }
  }
);

export const updateTeam = createAsyncThunk(
  'currentTeam/updateTeam',
  async (
    payload: {
      team: Team;
      successMessage: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await apiPatch(TEAM_ENDPOINT, payload.team);

      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );

      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const uploadTeamLogo = createAsyncThunk(
  'currentTeam/uploadTeamLogo',
  async (
    payload: { formData: FormData; teamId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      return await apiPost(
        `${TEAM_ENDPOINT}/upload-team-photo?teamId=${payload.teamId}`,
        payload.formData
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const getTeamPhoto = createAsyncThunk(
  'currentTeam/uploadTeam',
  async (teamId: string, { dispatch, rejectWithValue }) => {
    try {
      return await apiGet(`${TEAM_ENDPOINT}/get-team-photo?teamId=${teamId}`);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const unlinkToTeamIndividualLicense = createAsyncThunk(
  'teams/deleteTeamMember',
  async (payload: UnlinkToTeamData, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiDelete(
        `${TEAM_ENDPOINT}/remove-member?teamId=${payload.teamId}&userId=${payload.userId}&memberType=${payload.memberType}`
      );
      dispatch(
        addNotification({
          message: payload.successMessage,
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const deleteTeam = createAsyncThunk(
  'currentTeam/deleteTeam',
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiDelete(`${TEAM_ENDPOINT}?teamId=${teamId}`);

      dispatch(
        addNotification({
          message: 'Delete successfully deleted',
          type: 'success',
        })
      );

      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
