import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import apiGet from '../../utils/api-get';
import { catchErrors } from '../notifications';
import apiPost from '../../utils/api-post';
import { Comment } from '../../types/swim-data';
import apiDelete from '../../utils/api-delete';
import apiPatch from '../../utils/api-patch';

const COMMENT_ENDPOINT = `comment`;

interface PostComment {
  swimId: string;
  threadId?: number;
  content?: string;
  userId: string;
  status?: string;
}

interface UpdateComment {
  swimId: string;
  threadId?: number;
  content?: string;
  userId: string;
  status?: string;
  commentId: number;
}

export const updateContent = createAction<string>(`comment/updateContent`);

export const toggleShowDeleteModal = createAction(
  `comment/toggleShowDeleteModal`
);

export const setSelectedContent = createAction<Comment | {}>(
  `comment/setSelectedContent`
);

export const toggleShowEditInput = createAction(`comment/toggleShowEditInput`);

export const toggleShowReplyInput = createAction(
  `comment/toggleShowReplyInput`
);

export const resetCommentState = createAction('comment/resetCommentState');

export const fetchAllComments = createAsyncThunk(
  'comment/fetchAllComments',
  async (swimId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiGet(`${COMMENT_ENDPOINT}/list?swimId=${swimId}`);

      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const postComment = createAsyncThunk(
  'comment/postComment',
  async (payload: PostComment, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiPost(`${COMMENT_ENDPOINT}`, payload);

      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const updateComment = createAsyncThunk(
  'comment/updateComment',
  async (payload: UpdateComment, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiPatch(`${COMMENT_ENDPOINT}`, payload);

      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const patchMarkAsRead = createAsyncThunk(
  'comment/patchMarkAsRead',
  async (swimId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiPatch(
        `${COMMENT_ENDPOINT}/swim/read?swimId=${swimId}`
      );

      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const deleteComment = createAsyncThunk(
  'comment/deleteComment',
  async (commentId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiDelete(
        `${COMMENT_ENDPOINT}?commentId=${commentId}`
      );

      return result;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
