import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const selectChartState = (state: RootState) => state.chart;

export const selectLapCount = createSelector(
  selectChartState,
  (state) => state.lapCount
);

export const selectCurrentStroke = createSelector(
  selectChartState,
  (state) => state.currentStroke
);

export const selectLeftStrokeCount = createSelector(
  selectChartState,
  (state) => state.leftStrokeCount
);

export const selectRightStrokeCount = createSelector(
  selectChartState,
  (state) => state.rightStrokeCount
);

export const selectCurrentStrokeCount = createSelector(
  selectCurrentStroke,
  selectLeftStrokeCount,
  selectRightStrokeCount,
  (stroke, leftStrokeCount, rightStrokeCount) =>
    'Left' === stroke ? leftStrokeCount : rightStrokeCount
);

export const selectFilter = createSelector(
  selectChartState,
  (state) => state.filter
);

export const selectSelectedStrokes = createSelector(
  selectChartState,
  (state) => state.selectedStrokes
);

export const selectTimeValue = createSelector(
  selectChartState,
  (state) => state.timeValue
);

export const selectChartIsLoading = createSelector(
  selectChartState,
  (state) => state.isLoading
);

export const selectChartErrors = createSelector(
  selectChartState,
  (state) => state.errors
);

export const selectCurrentChart = createSelector(
  selectChartState,
  (state) => state.currentChart
);

export const selectVideoSync = createSelector(
  selectChartState,
  (state) => state.videoSync
);
