import { createAsyncThunk } from '@reduxjs/toolkit';

import { catchErrors } from '../notifications';

import { Profile } from '../../types/profile';

import apiGet from '../../utils/api-get';
import apiPost from '../../utils/api-post';
import apiPatch from '../../utils/api-patch';

export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async (userId: string, { rejectWithValue }) => {
    try {
      return await apiGet(`profile?userId=${userId}`);
    } catch (e: any) {
      // ! We are not displaying the error message here as not to confused newly signed up users
      throw rejectWithValue(e);
    }
  }
);

export const createProfile = createAsyncThunk(
  'profile/createProfile',
  async (payload: Profile, { rejectWithValue, dispatch }) => {
    try {
      return await apiPost('profile', payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (payload: Profile, { rejectWithValue, dispatch }) => {
    try {
      return await apiPatch('profile', payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
