import { createSelector } from 'reselect';

import { RootState } from '../store';

export const selectTeamsState = (state: RootState) => state.teams;

export const selectMemberDeletedStatus = createSelector(
  selectTeamsState,
  (state) => state.isMemberDeleted
);

export const selectTeam = createSelector(
  selectTeamsState,
  (state) => state.team
);

export const selectedTeam = createSelector(
  selectTeamsState,
  (state) => state.selectedTeam
);

export const selectTeamLicenses = createSelector(
  selectTeamsState,
  (state) => state.teamLicenses
);

export const selectedTeamAllocatedLicenses = createSelector(
  selectTeamsState,
  (state) => state.teamAllocatedLicenses
);

export const selectTeamList = createSelector(
  selectTeamsState,
  (state) => state.teamList
);

export const selectTeamsErrors = createSelector(
  selectTeamsState,
  (state) => state.errors
);

export const selectTeamsIsLoading = createSelector(
  selectTeamsState,
  (state) => state.isLoading
);

export const selectAvailableLicense = createSelector(
  selectTeamsState,
  (state) => state.availableLicense
);
