import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import apiGet from '../../utils/api-get';
import apiPost from '../../utils/api-post';

import { SWIMBETTER_ENDPOINT } from '../../constants/api-endpoints';

import { addNotification, catchErrors } from '../notifications';

type FetchJoinRequestsProps = {
  teamId: string;
};

type JoinRequestProps = {
  requestId: string;
};

const JOIN_REQUEST_ENDPOINT = `${SWIMBETTER_ENDPOINT}/request`;

export const resetSuccessStatus = createAction(
  'joinRequests/resetSuccessStatus'
);

export const fetchJoinRequestsList = createAsyncThunk(
  'joinRequests/fetchJoinRequestsList',
  async (teamId: string, { dispatch, rejectWithValue }) => {
    try {
      return await apiGet(`${JOIN_REQUEST_ENDPOINT}/pending?teamId=${teamId}`);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const acceptJoinRequest = createAsyncThunk(
  'joinRequests/acceptJoinRequest',
  async (payload: JoinRequestProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${JOIN_REQUEST_ENDPOINT}/accept`,
        payload
      );

      dispatch(
        addNotification({
          message: 'Accepted request.',
          type: 'success',
        })
      );
      return response.isSuccess;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const declineJoinRequest = createAsyncThunk(
  'joinRequests/declineJoinRequest',
  async (payload: JoinRequestProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${JOIN_REQUEST_ENDPOINT}/decline`,
        payload
      );

      dispatch(
        addNotification({
          message: 'Denied request.',
          type: 'warning',
        })
      );
      return response.isSuccess;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const declineIndividualRequest = createAsyncThunk(
  'joinRequests/declineIndividualRequest',
  async (payload: JoinRequestProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${JOIN_REQUEST_ENDPOINT}/decline-individual`,
        payload
      );

      dispatch(
        addNotification({
          message: 'Cancel Team Request.',
          type: 'warning',
        })
      );
      return response.isSuccess;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const sendJoinRequest = createAsyncThunk(
  'joinRequests/sendJoinRequest',
  async (
    payload: { teamId: string; sharedLicense: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await apiPost(`${JOIN_REQUEST_ENDPOINT}/join`, payload);

      dispatch(
        addNotification({
          message: `Your request was successfully submitted for team admin's processing. You'll receive a confirmation in your email about the status and the next steps.`,
          type: 'success',
        })
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const joinTeamLink = createAsyncThunk(
  'joinRequests/joinTeamLink',
  async (
    payload: { teamId: string; userId: string; licenseId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await apiPost(`${JOIN_REQUEST_ENDPOINT}/join-teamlink`, payload);

      dispatch(
        addNotification({
          message: `Your request was successfully submitted for team admin's processing. You'll receive a confirmation in your email about the status and the next steps.`,
          type: 'success',
        })
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
