import { createAction } from '@reduxjs/toolkit';

import { Notification } from '../../types/notification';

export const addNotification = createAction(
  'notifications/addNotification',
  (notification: Notification) => {
    // Adding duration before spreading notification so that when there is a duration in the passed notification
    // it will override the default duration which is 3000
    return { payload: { duration: 3000, ...notification } };
  }
);
export const removeNotification = createAction<number>(
  'notifications/removeNotification'
);

export const catchErrors = createAction(
  'notifications/catchErrors',
  (errors: any) => {
    const errorNotifications: Notification[] = [];

    if (errors && errors.length > 0) {
      errors.map((message: string) => {
        errorNotifications.push({
          message,
          type: 'error',
        });
      });
    }
    return { payload: errorNotifications };
  }
);
