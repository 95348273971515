import { createAsyncThunk } from '@reduxjs/toolkit';

import apiGet from '../../utils/api-get';
import apiPost from '../../utils/api-post';

import { SWIMBETTER_ENDPOINT } from '../../constants/api-endpoints';

import { addNotification, catchErrors } from '../notifications';
import { SEND_TEAM_INVITES } from '../../types/invite';

type FetchProps = {
  teamId: string;
  pageNumber: number;
  isCoachesAndAdmins: boolean;
};

type SendInviteProps = {
  invites: { email: string; type: number }[];
  teamId: string;
};

type InviteProps = {
  inviteId: string;
};

const PENDING_INVITE_ENDPOINT = `${SWIMBETTER_ENDPOINT}/invite`;

export const acceptTeamInvite = createAsyncThunk(
  'pendingInvites/acceptTeamInvite',
  async (payload: InviteProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/team-accept`,
        payload
      );
      dispatch(
        addNotification({
          message: 'Team invite accepted!',
          type: 'success',
        })
      );
      return response;
    } catch (e) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const acceptCoachesAdminInvite = createAsyncThunk(
  'pendingInvites/acceptCoachesAdminInvite',
  async (payload: InviteProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/team-accept-admin`,
        payload
      );
      dispatch(
        addNotification({
          message: 'Coach invite accepted!',
          type: 'success',
        })
      );
      return response;
    } catch (e) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const sendTeamInvites = createAsyncThunk(
  'pendingInvites/sendTeamInvites',
  async (payload: SEND_TEAM_INVITES, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/team-multiple`,
        payload
      );
      dispatch(
        addNotification({
          message: 'Email invitations had been sent!',
          type: 'success',
        })
      );
      return response;
    } catch (e) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const sendCoachAdminInvites = createAsyncThunk(
  'pendingInvites/sendCoachAdminInvites',
  async (payload: SEND_TEAM_INVITES, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/team-multiple-admincoach`,
        payload
      );
      dispatch(
        addNotification({
          message: 'Email invitations had been sent!',
          type: 'success',
        })
      );
      return response;
    } catch (e) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchPendingTeamInvites = createAsyncThunk(
  'pendingInvites/fetchPendingTeamInvites',
  async (teamId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet(
        `${PENDING_INVITE_ENDPOINT}/get-by-team?teamId=${teamId}`
      );
      return response.reverse();
    } catch (e) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchPendingAdminCoachInvites = createAsyncThunk(
  'pendingInvites/fetchPendingAdminCoachInvites',
  async (teamId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiGet(
        `${PENDING_INVITE_ENDPOINT}/get-admin-by-team?teamId=${teamId}`
      );
      return response.reverse();
    } catch (e) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchPendingInvitesList = createAsyncThunk(
  'pendingInvites/fetchPendingInvitesList',
  async (
    { isCoachesAndAdmins, ...payload }: FetchProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const filter = isCoachesAndAdmins
        ? {
            coach: 1,
            admin: 1,
            swimmer: 0,
          }
        : {
            coach: 0,
            admin: 0,
            swimmer: 1,
          };
      return {
        paginationData: await apiGet(`${PENDING_INVITE_ENDPOINT}/pending`, {
          ...payload,
          ...filter,
        }),
        filter,
      };
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const sendPendingInvites = createAsyncThunk(
  'pendingInvites/sendPendingInvites',
  async (payload: SendInviteProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/member`,
        payload
      );
      dispatch(
        addNotification({
          message: 'Email invitations had been sent!',
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      if (e.message) {
        dispatch(
          addNotification({
            message: e.message,
            type: 'error',
          })
        );
      }

      dispatch(catchErrors(e));

      throw rejectWithValue(e);
    }
  }
);

export const resendPendingInvite = createAsyncThunk(
  'pendingInvites/resendPendingInvite',
  async (payload: { inviteId: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/resend`,
        payload
      );

      dispatch(
        addNotification({
          message: 'Invitation sent.',
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const resendPendingCoachesAdminInvite = createAsyncThunk(
  'pendingInvites/resendPendingCoachesAdminInvite',
  async (payload: { inviteId: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/resend-admin`,
        payload
      );

      dispatch(
        addNotification({
          message: 'Invitation sent.',
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const cancelPendingInvite = createAsyncThunk(
  'pendingInvites/cancelPendingInvite',
  async (inviteId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/individual-cancel?InviteId=${inviteId}`
      );

      dispatch(
        addNotification({
          message: 'Invite is canceled.',
          type: 'info',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const fetchPendingInviteDetails = createAsyncThunk(
  'pendingInvites/fetchPendingInviteDetails',
  async (payload: InviteProps, { dispatch, rejectWithValue }) => {
    try {
      return await apiGet(PENDING_INVITE_ENDPOINT, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const acceptPendingInvite = createAsyncThunk(
  'pendingInvites/acceptPendingInvite',
  async (payload: InviteProps, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiPost(
        `${PENDING_INVITE_ENDPOINT}/team-accept`,
        payload
      );
      dispatch(
        addNotification({
          message: 'Invitation has been accepted.',
          type: 'success',
        })
      );
      return response;
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);

export const declinePendingInvite = createAsyncThunk(
  'pendingInvites/declinePendingInvite',
  async (inviteId: string, { dispatch, rejectWithValue }) => {
    try {
      await apiPost(`${PENDING_INVITE_ENDPOINT}/decline?InviteId=${inviteId}`);

      dispatch(
        addNotification({
          message: 'Invitation has been declined.',
          type: 'success',
        })
      );
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);
