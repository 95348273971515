import { createSelector } from 'reselect';

import { RootState } from '../store';

const selectUserState = (state: RootState) => state.user;

export const selectUser = createSelector(
  selectUserState,
  (state) => state.user
);

export const selectKey = createSelector(selectUserState, (state) => state.key);

export const selectToken = createSelector(
  selectUserState,
  (state) => state.token
);

export const selectUserIsLoading = createSelector(
  selectUserState,
  (state) => state.isLoading
);

export const selectUserErrors = createSelector(
  selectUserState,
  (state) => state.errors
);

export const selectUserTeams = createSelector(
  selectUserState,
  (state) => state.userTeams
);

export const selectUserSubscriptions = createSelector(
  selectUserState,
  (state) => state.userSubscriptions
);

export const selectUserHasSignOut = createSelector(
  selectUserState,
  (state) => state.userHasSignOut
);
