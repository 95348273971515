import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectGroupsState = (state: RootState) => state.groups;

export const selectGroupsList = createSelector(
  selectGroupsState,
  (state) => state.groups
);

export const selectGroup = createSelector(
  selectGroupsState,
  (state) => state.group
);

export const selectGroupResponseInfo = createSelector(
  selectGroupsState,
  (state) => state.responseInfo
);

export const selectGroupsIsLoading = createSelector(
  selectGroupsState,
  (state) => state.isLoading
);

export const selectGroupsErrors = createSelector(
  selectGroupsState,
  (state) => state.errors
);

export const selectAddMemberModalStatus = createSelector(
  selectGroupsState,
  (state) => state.addMemberModal
);

export const selectDeleteGroupModalStatus = createSelector(
  selectGroupsState,
  (state) => state.deleteGroupModal
);

export const selectFilteredGroups = createSelector(
  selectGroupsState,
  (state) => state.filteredGroups
);
