import axios from 'axios';

import isTokenExpired from './json-token-checker';
import { fetchLocalStorageData, removeLocalStorageData } from './localstorage';
import { getLocalelanguage } from './get-locale';

// I added it here so that the handling for axios.put won't be duplicated everytime
// it will be used
const apiPut = async (endpoint: string, params?: any, customConfig?: {}) => {
  try {
    const lang = getLocalelanguage();

    let apiEndPoint = endpoint.startsWith(process.env.API_ENDPOINT_VIDEOCHART) || endpoint.includes('amazonaws.com') ? "" : process.env.API_ENDPOINT;

    const accessToken = fetchLocalStorageData('accessToken');

    if (accessToken && isTokenExpired(accessToken)) {
      removeLocalStorageData('accessToken');
      // The HTTP 401 Status code only exists for GET api for the meantime
      throw {
        response: {
          data: {
            message: 'Unauthorized',
          },
        },
      };
    }

    const queryParams = {
      locale : lang
    }

    let config = {};
    if (!endpoint.startsWith(process.env.API_ENDPOINT_VIDEOCHART) && !endpoint.includes('amazonaws.com')) {
      config = accessToken
        ? {
          params : queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        : {};
    }

    // const config = accessToken
    //   ? {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   }
    //   : {};

    const { data } = await axios.put(
      `${apiEndPoint}${endpoint}`,
      params,
      customConfig ? customConfig : config
    );
    return data.data;
  } catch (e: any) {
    if (e.response.data.data) {
      throw e.response.data;
    } else if (
      e.response.data?.dataMessage &&
      e.response.data.dataMessage.length > 0
    ) {
      throw e.response.data;
    } else if (e.response.data?.message && e.response.data.message !== '') {
      throw [e.response.data.message];
    } else {
      const errors = e.response.data.errors;
      throw Object.keys(errors).map((key) => errors[key][0]);
    }
  }
};

export default apiPut;
