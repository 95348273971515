import { Provider } from 'react-redux';
import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import NextNprogress from 'nextjs-progressbar';
import { LicenseInfo } from '@mui/x-license-pro';

import { store } from '../redux/store';

import '../styles/reset.css';
import '../styles/globals.css';
import '../styles/variables.css';

import { ErrorsWrapper } from '../context/errors';
import { Notifications } from '../components/common/notifications';

import TagManager from 'react-gtm-module';

import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Head from 'next/head';

import { NextIntlProvider } from 'next-intl';

import { useState } from 'react';
import { getLocale } from '../utils/get-locale';

const colorGlobal = {
  palette: {
    primary: {
      light: '#f27e4e',
      main: '#ff4305',
    },
    common: {
      white: '#FFFFFF',
      extraLightGray: '#BFBFBF',
      lightGray: '#98A2B3',
      midGray: '#757575',
      darkGray: '#667085',
      black: '#000000',
      left: '#FDB022',
      right: '#53B1FD',
      body: '#e5e5e5',
    },
    neutral: {
      main: '#FFFFFF',
      contrastText: '#ff4305',
    },
    grey: {
      50: '#F9FAFB',
      100: '#F2F4F7',
      200: '#EAECF0',
      300: '#D0D5DD',
      400: '#98A2B3',
      500: '#667085',
      700: '#344054',
    },
    catalyst: {
      50: '#FFF2EE',
      200: '#FFB49B',
      700: '#AD2B00',
    },
  },
};

const MainTheme: Theme = createTheme({
  typography: {
    fontFamily: 'Archivo, sans-serif',
    body1: {
      fontSize: '1rem',
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: '2.375rem',
    },
    subtitle2: {
      fontSize: '0.75rem',
      color: colorGlobal.palette.grey[400],
      lineHeight: '1.125rem',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontSize: '1rem',
          fontWeight: 600,
          padding: '10px 25px',
          borderRadius: '2.5rem',
          lineHeight: '1.5rem',
          ...(ownerState.variant === 'outlined' && {
            border: `1px solid ${colorGlobal.palette.catalyst[200]}`,
          }),
        }),
      },
    },
  },
  ...colorGlobal,
});

LicenseInfo.setLicenseKey(process.env.muiProLicense);

declare module '@mui/material/styles' {
  interface CommonColors {
    white: string;
    lightGray: string;
    darkGray: string;
    black: string;
    left: string;
    right: string;
    body: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    catalyst: Palette['grey'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    catalyst?: PaletteOptions['grey'];
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    catalyst: true;
  }
}

export const locales = ['en', 'ja', 'fil', 'de', 'fr', 'es', 'pt', 'zh-CN', 'it', 'pt-BR', 'pt-PT', 'fr-FR', 'it-IT', 'de-DE', 'es-ES', 'nl', 'nl-NL', 'pl', 'sv', 'sv-SE', 'fr-CA', 'fr-CH', 'de-AT', 'de-CH', 'it-CH', 'zh-HK', 'zh-TW', 'zh', 'es-DO', 'es-MX', 'es-PR', 'es-US'];

function MyApp({ Component, pageProps }: AppProps) {
  const [message, setMessage] = useState<any>(null);
  const [lang, setLang] = useState(null);

  useEffect(() => {
    setLang(navigator.language);
  }, []);

  useEffect(() => {
    const fetchMessage = async (lang: string) => {

      const res = await getLocale(lang);

      const localMes = JSON.parse(res.data);
      setMessage(localMes);
    };
    
    const isSupported = locales.includes(lang);

    if (lang) {
      if (isSupported) {
        fetchMessage(lang);
      } else {
        fetchMessage('en_US');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!message) return null;

  return (
    <>
      <Head>
        <meta name="viewport" content="viewport-fit=cover" />
      </Head>
      <Provider store={store}>
        <ThemeProvider theme={MainTheme}>
          <CssBaseline />
          <Notifications />
          <ErrorsWrapper>
            <NextNprogress />
            <NextIntlProvider
              locale={lang ? lang : 'en'}
              timeZone="Europe/Vienna"
              messages={message}
            >
              <Component {...pageProps} />
            </NextIntlProvider>
          </ErrorsWrapper>
        </ThemeProvider>
      </Provider>
    </>
  );
}
export default MyApp;
