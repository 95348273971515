import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectProductsState = (state: RootState) => state.products;

export const selectProductsList = createSelector(
  selectProductsState,
  (state) => state.productsList
);
export const selectProductsIsLoading = createSelector(
  selectProductsState,
  (state) => state.isLoading
);
export const selectProductsErrors = createSelector(
  selectProductsState,
  (state) => state.errors
);
