import { configureStore } from '@reduxjs/toolkit';
import {
  fetchLocalStorageData,
  setLocalStorageData,
} from '../utils/localstorage';

import rootReducer from './reducers';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: fetchLocalStorageData('state'),
});

store.subscribe(() => {
  setLocalStorageData('state', store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
