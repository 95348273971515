import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { ReduxState } from '../../types/redux-state';

import {
  deleteVideo,
  editMarkState,
  fetchPresignedURLForUpload,
  fetchVideoData,
  getChartData,
  postUploadVideo,
  resetState,
  setNudgeEnabledTime,
  toggleNudge,
  updateMetadata,
  updateSeekTime,
  updateSwimVideoData,
} from './actions';
import {
  MetaDataResponse,
  PresignedURLVideoField,
  ResponsePresignedURL,
  videoData,
} from '../../types/video-chart';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

interface VideoChartState extends ReduxState {
  videoData: videoData | null;
  presignedUrlResponseData: ResponsePresignedURL | null;
  uploadVideoResponseData: any; // We dont get any response data from AWS Only status will come.
  deleteVideoResponseData: any;
  updateMetaDataResponse: MetaDataResponse | null;
  isNudge: boolean;
  nudgeEnabledTime: number | null;
  isMarkEditClicked: boolean;
  forceVsTimeChartData: any;
  currentSeekedTime: number | null;
}

const initialState: VideoChartState = {
  videoData: { videourl: null, status: null, metadata: null },
  presignedUrlResponseData: { url: null, fields: null },
  uploadVideoResponseData: null,
  deleteVideoResponseData: null,
  updateMetaDataResponse: null,
  errors: [],
  isLoading: false,
  isNudge: false,
  nudgeEnabledTime: null,
  isMarkEditClicked: false,
  forceVsTimeChartData: null,
  currentSeekedTime: null,
};

export const chartVideoReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchVideoData.fulfilled, (state, { payload }) => {
      state.videoData = payload ? payload : null;
    })
    .addCase(fetchPresignedURLForUpload.fulfilled, (state, { payload }) => {
      state.presignedUrlResponseData = payload ? payload : null;
    })
    .addCase(postUploadVideo.fulfilled, (state, { payload }) => {
      //Updating status manully to update loading status
      state.uploadVideoResponseData = { status: 'Success' };
    })
    .addCase(deleteVideo.fulfilled, (state, { payload }) => {
      //Updating status manully to update loading status
      state.deleteVideoResponseData = { status: 'Success' };
    })
    .addCase(updateMetadata.fulfilled, (state, { payload }) => {
      state.updateMetaDataResponse = payload ? payload : null;
    })
    .addCase(resetState, (state) => {
      state.presignedUrlResponseData = null;
      state.uploadVideoResponseData = null;
      state.deleteVideoResponseData = null;
      state.updateMetaDataResponse = null;
      state.currentSeekedTime = null;
      state.forceVsTimeChartData = null;
      state.isMarkEditClicked = null;
      state.isNudge = null;
      state.nudgeEnabledTime = null;
    })
    .addCase(editMarkState, (state, action) => {
      state.isMarkEditClicked = Boolean(action.payload);
    })
    .addCase(getChartData.fulfilled, (state, { payload }) => {
      state.forceVsTimeChartData = payload ? payload : null;
    })
    .addCase(updateSwimVideoData, (state, action) => {
      if (action.payload) {
        state.videoData = {
          ...state.videoData,
          status: action.payload.status,
          metadata: {
            VideoStartMarker: action.payload.metadata.videoStartMarker
              ? action.payload.metadata.videoStartMarker
              : 0,
          },
        };
      }
    })
    .addCase(updateSeekTime, (state, action) => {
      state.currentSeekedTime = action.payload;
    })
    .addCase(toggleNudge, (state, action) => {
      state.isNudge = action.payload;
    })
    .addCase(setNudgeEnabledTime, (state, action) => {
      state.nudgeEnabledTime = action.payload;
    });
});
