import { useEffect, useState } from 'react';

import { Notification } from '../../types/notification';

import {
  selectNotifications,
  removeNotification as removeNotificationAction,
} from '../../redux/notifications';

import styles from './notifications.module.css';
import { useDispatch, useSelector } from '../../hooks/redux-hooks';
import { signOutUser } from '../../redux/user';

import { fetchLocalStorageData } from '../../utils/localstorage';
import isTokenExpired from '../../utils/json-token-checker';

export function Notifications() {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [duration, setDuration] = useState<number>(3000);

  const notificationState = useSelector(selectNotifications);

  const localStorageAccessToken = fetchLocalStorageData('accessToken');

  useEffect(() => {
    setNotifications(notificationState);
  }, [notificationState]);

  const getMinRemoveTime = () => {
    return notifications.reduce(
      (min, notification) =>
        notification.duration && notification.duration < min
          ? notification.duration
          : min,
      0
    );
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      if (notifications.length > 0) {
        const min = getMinRemoveTime() || duration;
        // get index of minimum duration
        const index = notifications.findIndex(
          (notification) => notification.duration === min
        );

        removeNotification(index);
        if (duration !== min) {
          setDuration(min);
        }
      }
    }, duration);

    return () => {
      clearTimeout(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  // If there is an unathorized error and the token is expired
  useEffect(() => {
    if (
      notifications.find(
        (notification) => notification.message === 'Unauthorized'
      ) &&
      ((localStorageAccessToken && isTokenExpired(localStorageAccessToken)) ||
        !localStorageAccessToken)
    ) {
      dispatch(signOutUser());
    }
  }, [localStorageAccessToken, notifications, dispatch]);

  const removeNotification = (index: number) => {
    dispatch(removeNotificationAction(index));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.notifications}>
          {notifications.map((notification: Notification, index: number) => {
            return (
              <div
                key={index}
                className={`${styles.notification} ${
                  styles[
                    `notification--${
                      notification.type ? notification.type : 'info'
                    }`
                  ]
                }`}
              >
                <div
                  className={styles.notification__close}
                  onClick={() => removeNotification(index)}
                >
                  x
                </div>

                {notification.title && (
                  <div className={styles.notification__title}>
                    {notification.title}
                  </div>
                )}

                {notification.message && (
                  <div className={styles.notification__message}>
                    {notification.message}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
