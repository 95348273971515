import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import type { RootState } from '../redux/store';

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useDispatch = () => useReduxDispatch<ThunkAppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
