import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { ReduxState } from '../../types/redux-state';
import {
  fetchAllComments,
  resetCommentState,
  setSelectedContent,
  toggleShowDeleteModal,
  toggleShowEditInput,
  toggleShowReplyInput,
  updateContent,
} from './actions';
import { Comment } from '../../types/swim-data';

interface CommentState extends ReduxState {
  comments: Comment[];
  selectedComment: Partial<Comment>;
  deleteModal: boolean;
  showEditInput: boolean;
  showReplyInput: boolean;
  content: string;
  hasAction: boolean;
}

const initialState: CommentState = {
  comments: [],
  content: '',
  isLoading: false,
  deleteModal: false,
  showEditInput: false,
  showReplyInput: false,
  selectedComment: {},
  errors: [],
  hasAction: false,
};

export const commentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllComments.fulfilled, (state, { payload }) => {
      state.comments = payload;
    })
    .addCase(updateContent, (state, { payload }) => {
      state.content = payload;
    })
    .addCase(toggleShowDeleteModal, (state) => {
      state.deleteModal = !state.deleteModal;
    })
    .addCase(toggleShowReplyInput, (state) => {
      state.showReplyInput = !state.showReplyInput;
      state.hasAction = !state.hasAction;
    })
    .addCase(toggleShowEditInput, (state) => {
      state.showEditInput = !state.showEditInput;
      state.hasAction = !state.hasAction;
    })
    .addCase(setSelectedContent, (state, { payload }) => {
      state.selectedComment = payload;
    })
    .addCase(resetCommentState, () => {
      return initialState;
    })
    .addMatcher(isAnyOf(fetchAllComments.pending), (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    .addMatcher(isAnyOf(fetchAllComments.fulfilled), (state) => {
      state.isLoading = true;
      state.errors = [];
    });
});
